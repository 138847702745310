import { inject, injectable } from 'inversify';
import { IPendingChangesService } from './IPendingChangesService';
import { getAspectOrDefault, INotificationService, INotificationServiceSymbol, ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import { IBookmark, isILayoutAwareBookmark, isIListBookmark, isIObjectBookmark, isITrackableBookmark } from '../bookmarks';
import { BookmarkActionsAspectIdentifier, IBookmarkActionsAspect } from '../aspects';

export enum PendingChangesFeedbackValue {
  Save = 'Save',
  Cancel = 'Cancel',
  Discard = 'Discard'
}

@injectable()
export class PendingChangesService implements IPendingChangesService {
  constructor(
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(IFeedbackServiceSymbol) private readonly feedbackService: IFeedbackService,
    @inject(INotificationServiceSymbol) private readonly notificationService: INotificationService
  ) {}

  async getLeavePermission(bookmark: IBookmark | undefined, notifyInGlobalArea?: boolean, preventUIUpdate?: boolean): Promise<boolean> {
    const symbol = Symbol.for('PendingChangesService');
    if ((bookmark && isIListBookmark(bookmark) && bookmark.hasInlineEditChanges()) || (isIObjectBookmark(bookmark) && bookmark.hasChildChanges())) {
      return this.feedbackService.getConfirmation(
        this.translationService.translate('QuinoPendingChangesFeedback.Title'),
        this.translationService.translate('QuinoPendingChangesFeedback.Text'),
        this.translationService.translate('Discard'),
        'dx-icon material-icons-outlined replay',
        this.translationService.translate('ReturnToEditor'),
        'dx-icon material-icons-outlined edit'
      );
    } else if (bookmark && isITrackableBookmark(bookmark) && bookmark.hasChanges()) {
      const notificationArea = notifyInGlobalArea ? 'global' : 'default';
      const bookmarkActionOptions = isILayoutAwareBookmark(bookmark)
        ? getAspectOrDefault<IBookmarkActionsAspect>(bookmark.layout, BookmarkActionsAspectIdentifier)
        : null;

      const feedback = await this.getLeavingFeedback(bookmarkActionOptions);
      if (feedback === PendingChangesFeedbackValue.Cancel) {
        return false;
      } else if (feedback === PendingChangesFeedbackValue.Discard) {
        bookmark.reset(preventUIUpdate);
        return true;
      } else if (feedback === PendingChangesFeedbackValue.Save) {
        if (isIObjectBookmark(bookmark)) {
          const validationResult = await bookmark.validate();
          if (validationResult.hasErrors) {
            this.notificationService.notify(
              {
                message: this.translationService.translate('Notification.ValidationError'),
                area: notificationArea,
                autoDisappear: notifyInGlobalArea
              },
              symbol
            );

            return false;
          }
        }

        return bookmark
          .save(preventUIUpdate)
          .then(() => {
            if (!preventUIUpdate) {
              this.notificationService.notify(
                {
                  type: 'success',
                  message: this.translationService.translate('SaveConfirmation'),
                  area: 'global',
                  autoDisappear: true
                },
                symbol
              );
            } else {
              this.notificationService.clearNotification(symbol);
            }

            return true;
          })
          .catch((error) => {
            this.notificationService.notify(
              {
                message: this.translationService.translate('Notification.ErrorBoundaryError'),
                messageDetails: String(error),
                area: notificationArea,
                autoDisappear: notifyInGlobalArea
              },
              symbol
            );

            return false;
          });
      }
    }

    return true;
  }

  getLeavingFeedback = async (bookmarkActionOptions?: IBookmarkActionsAspect | null): Promise<string> => {
    const feedbackOptions = [
      {
        value: PendingChangesFeedbackValue.Cancel,
        text: this.translationService.translate('ReturnToEditor'),
        icon: 'dx-icon material-icons-outlined edit',
        isPrimary: false,
        isCancelOption: true
      },
      {
        value: PendingChangesFeedbackValue.Discard,
        text: this.translationService.translate('Discard'),
        icon: 'dx-icon material-icons-outlined replay',
        isPrimary: bookmarkActionOptions?.hideSave,
        isCancelOption: false
      }
    ];

    const saveCaption = this.translationService.translate(bookmarkActionOptions?.customSaveTranslationKey || 'Save');

    !bookmarkActionOptions?.hideSave &&
      feedbackOptions.push({
        value: PendingChangesFeedbackValue.Save,
        text: saveCaption,
        icon: bookmarkActionOptions?.customSaveIcon || 'material-icons-outlined save',
        isPrimary: true,
        isCancelOption: false
      });

    return this.feedbackService.getCustomFeedback(
      this.translationService.translate('QuinoPendingChangesFeedback.Title'),
      this.translationService.translate('QuinoPendingChangesFeedback.Text'),
      feedbackOptions
    );
  };
}
