/**
 * The symbol for the IRenderer service.
 */
export const IRendererSymbol = Symbol.for('IRenderer');

/**
 * A renderer to inject components into the DOM.
 */
export interface IRenderer {
  /**
   * Render the given component.
   * @param component - the component to render.
   */
  inject: (component: any) => () => void;
}
