import { IMetaAspect } from '../IMetaAspect';
import { IExpression } from '../../expressions';

/**
 * The identifier.
 */
export const ExpressionBasedValidationAspectIdentifier = 'ExpressionBasedValidationAspect';

/**
 * Aspect containing a custom validation expression for the given element.
 */
export interface IExpressionBasedValidationAspect extends IMetaAspect {
  expression: IExpression;
  messageTemplate: string;
}
