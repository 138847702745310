export class FieldValidationErrorCodes {
  public static DATATYPE_MISMATCH_STRING = 'VAL-10';
  public static DATATYPE_MISMATCH_DATE = 'VAL-11';
  public static DATATYPE_MISMATCH_DATETIME = 'VAL-12';
  public static DATATYPE_MISMATCH_TIME = 'VAL-13';
  public static DATATYPE_MISMATCH_BOOLEAN = 'VAL-14';
  public static DATATYPE_MISMATCH_INTEGER = 'VAL-15';
  public static DATATYPE_MISMATCH_LARGEINTEGER = 'VAL-16';
  public static DATATYPE_MISMATCH_SMALLINTEGER = 'VAL-17';
  public static DATATYPE_MISMATCH_TINYINTEGER = 'VAL-18';
  public static DATATYPE_MISMATCH_CURRENCY = 'VAL-19';
  public static DATATYPE_MISMATCH_DOUBLE = 'VAL-20';
  public static BOUNDS_MIN_VALUE = 'VAL-21';
  public static BOUNDS_MAX_VALUE = 'VAL-22';
  public static REGEX_NOT_MATCH = 'VAL-23';
  public static MAXLENGTH = 'VAL-24';
  public static OPTIONVALUES_NOT_FOUND = 'VAL-25';
  public static REQUIRED = 'VAL-26';
  public static CUSTOM_EXPRESSION = 'VAL-27';
}
