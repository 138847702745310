import React, { FC, useCallback } from 'react';
import { formatDate } from 'devextreme/localization';
import { TCalendarAppointment } from './service';
import { useService } from '../ioc';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import { ITranslationService, ITranslationServiceSymbol } from '@quino/core';
import { IMarkdownConverter, IMarkdownConverterSymbol } from '../rendering';

export interface ICalendarEntryPopoverProps {
  appointment: TCalendarAppointment;
  navigateToDetail: (event: any, data: TCalendarAppointment) => void;
  hideLink: boolean;
}

const dateTimeFormat = 'dd.MM.yyyy, HH:mm';
const dateOnlyFormat = 'dd.MM.yyyy';

export const CalendarEntryTooltipContent: FC<ICalendarEntryPopoverProps> = (props) => {
  const { appointment, navigateToDetail } = props;

  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);

  const priorityItem = useCallback(
    (appointmentData: TCalendarAppointment): React.ReactElement => {
      if (appointmentData.priority && appointmentData.priority != 'Undefined') {
        return (
          <>
            <div className='appointment-details-item caption'>{`${translationService.translate('Calendar.Popover.Details.Priority')}:`}</div>
            <div className='appointment-details-priority-container'>
              <span className={'priority-badge ' + appointmentData.priority.toLowerCase()} />
              <div className='appointment-details-item text'>
                {translationService.translate('Calendar.Popover.Details.Priority.' + appointmentData.priority)}
              </div>
            </div>
          </>
        );
      }

      return <></>;
    },
    [translationService]
  );

  const defaultItem = useCallback(
    (translationSuffix: string, textContent: string): React.ReactElement => {
      const convertedMarkdown = markdownConverter.convertToHtml(textContent || '');
      return (
        <>
          <div className='appointment-details-item caption'>{`${translationService.translate(
            'Calendar.Popover.Details.' + translationSuffix
          )}:`}</div>
          {markdownConverter.isMarkdown(textContent) ? (
            <div className='appointment-details-item text' dangerouslySetInnerHTML={{ __html: convertedMarkdown }} />
          ) : (
            <div className='appointment-details-item text'>{textContent}</div>
          )}
        </>
      );
    },
    [markdownConverter, translationService]
  );

  const topSection = useCallback(
    (appointmentData: TCalendarAppointment): React.ReactElement => {
      if (appointmentData.start != null) {
        const startDate = new Date(appointmentData.start);
        const endDate = appointmentData.end != null ? new Date(appointmentData.end) : null;

        return (
          <div className='appointment-details-section'>
            {priorityItem(appointmentData)}
            {defaultItem(
              appointmentData.isAllDay ? 'Date' : 'Start',
              formatDate(startDate, appointmentData.isAllDay ? dateOnlyFormat : dateTimeFormat)
            )}
            {!appointmentData.isAllDay && endDate != null && defaultItem('End', formatDate(endDate, dateTimeFormat))}
          </div>
        );
      }

      return <></>;
    },
    [defaultItem, priorityItem]
  );

  const middleSection = useCallback(
    (appointmentData: TCalendarAppointment): React.ReactElement => {
      if (appointmentData.organizer != null || appointmentData.location != null) {
        return (
          <div className='appointment-details-section'>
            {appointmentData.organizer != null && defaultItem('Organizer', appointmentData.organizer)}
            {appointmentData.location != null && defaultItem('Location', appointmentData.location)}
          </div>
        );
      }

      return <></>;
    },
    [defaultItem]
  );

  const bottomSection = useCallback(
    (appointmentData: TCalendarAppointment): React.ReactElement => {
      if (appointmentData.description != null) {
        const convertedMarkdown = markdownConverter.convertToHtml(appointmentData.description);
        return (
          <div>
            <div className='appointment-details-item caption'>{`${translationService.translate('Calendar.Popover.Details.Description')}:`}</div>
            {markdownConverter.isMarkdown(appointmentData.description) ? (
              <div className='appointment-details-item description' dangerouslySetInnerHTML={{ __html: convertedMarkdown }} />
            ) : (
              <p className='appointment-details-item description'>{appointmentData.description}</p>
            )}
          </div>
        );
      }

      return <></>;
    },
    [translationService]
  );

  return (
    <div className={'quino-calendar-popover'} key={appointment.id}>
      <Toolbar elementAttr={{ class: 'dx-popup-title' }}>
        <Item location='before' text={appointment.summary} cssClass={'dx-toolbar-label'} />
        <Item
          location='after'
          widget='dxButton'
          visible={appointment.metaClass !== null && appointment.id !== null && !props.hideLink}
          options={{
            stylingMode: 'text',
            type: 'default',
            icon: 'material-icons-outlined link',
            hint: translationService.translate('Calendar.Popover.Appointment.Hint'),
            onClick: (e: any) => navigateToDetail(e, appointment)
          }}
        />
      </Toolbar>
      <div className='quino-calendar-popover-content'>
        {topSection(appointment)}
        {middleSection(appointment)}
        {bottomSection(appointment)}
      </div>
    </div>
  );
};
