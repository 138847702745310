/**
 * Symbol for the title calculator.
 */
import { IMetaProperty } from '../meta';
import { IGenericObject } from './IGenericObject';

export const ITitleCalculatorSymbol = Symbol.for('ITitleCalculator');

/**
 * Calculate a title for a given class and object.
 */
export interface ITitleCalculator {
  /**
   * Generate the title for the given object and meta class.
   * @param obj - the object to generate the title for.
   * @param metaClass - the meta class to use.
   */
  generate(obj: any, metaClass: string, customTitleLayout?: string): string;

  /**
   * Get the title for the given meta property.
   * @param metaProperty - the property to calculate the caption for.
   * @param genericObject - the object the property relates to.
   */
  getTitle(metaProperty: IMetaProperty, genericObject: IGenericObject): string;
}
