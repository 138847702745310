import { IAuthorizationService, IMetaClass, IMetaLayout, IMetaRelation, QuinoCoreServiceSymbols, getAspectOrDefault } from '@quino/core';
import { inject, injectable } from 'inversify';
import { IQuinoDataGridConfiguration } from './IQuinoDataGridConfiguration';
import { IQuinoDataGridConfigurationService } from './IQuinoDataGridConfigurationService';
import { GroupingAspectIdentifier, IGroupingAspect } from '../../aspects';

@injectable()
export class QuinoDataGridConfigurationService implements IQuinoDataGridConfigurationService {
  constructor(@inject(QuinoCoreServiceSymbols.IAuthorizationService) private readonly authorizationService: IAuthorizationService) {}

  getMetaDataGridConfiguration(element: IMetaLayout, metaClass: IMetaClass): IQuinoDataGridConfiguration {
    const showDelete = this.authorizationService.getAuthorization(metaClass.name).canDelete();

    return { ...this.defaultConfig, showDelete: showDelete };
  }

  getInlineGridConfiguration(layout: IMetaLayout, relation?: IMetaRelation): IQuinoDataGridConfiguration {
    const showDelete = relation ? this.authorizationService.getAuthorization(relation.targetClass).canDelete() : false;

    return { ...this.defaultConfig, ...this.defaultInlineConfig, showDelete: showDelete };
  }

  getSearchEditGridConfiguration(relation?: IMetaRelation): IQuinoDataGridConfiguration {
    const showDeleteDefault = this.defaultSearchEditConfig.showDelete != undefined ? this.defaultSearchEditConfig.showDelete : true;
    const showDelete = relation ? this.authorizationService.getAuthorization(relation.targetClass).canDelete() : false;

    return { ...this.defaultConfig, ...this.defaultSearchEditConfig, showDelete: showDeleteDefault && showDelete };
  }

  getDefaultGroupColumnName(layout: IMetaLayout, relation?: IMetaRelation | undefined): string | undefined {
    const relationAspect = relation ? getAspectOrDefault<IGroupingAspect>(relation, GroupingAspectIdentifier) : undefined;
    const layoutAspect = getAspectOrDefault<IGroupingAspect>(layout, GroupingAspectIdentifier);

    return relationAspect ? relationAspect.columnName : layoutAspect?.columnName;
  }

  private readonly defaultInlineConfig: Partial<IQuinoDataGridConfiguration> = {
    useSearch: true,
    usePaging: true,
    useGrouping: true,
    useSorting: true,
    useFilterPanel: false,
    useColumnChooser: false,
    useColumnResizing: true,
    useColumnReordering: true,
    useColumnSelection: false,
    itemsPerPage: 10,
    useAggregation: false,
    showPopupCreate: true
  };

  private readonly defaultSearchEditConfig: Partial<IQuinoDataGridConfiguration> = {
    useSearch: true,
    useColumnChooser: false,
    useGrouping: false,
    useColumnResizing: true,
    drillDownMode: 'All',
    useDrillDownColumn: false,
    useCrossLinks: false,
    useDoubleClick: false,
    usePaging: true,
    showDelete: false,
    selectionMode: 'None',
    useExport: false,
    useSorting: false,
    itemsPerPage: 5,
    useAggregation: false
  };

  private readonly defaultConfig: IQuinoDataGridConfiguration = {
    useColumnFixing: false,
    useExport: true,
    useFilterPanel: true,
    useGrouping: true,
    useGroupPanel: false,
    useHeaderFilter: true,
    useHeaderSearch: true,
    useMultipleSorting: true,
    usePaging: true,
    useSearch: true,
    useSorting: true,
    useDoubleClick: true,
    useColumnChooser: true,
    useColumnReordering: false,
    useColumnSelection: false,
    useColumnResizing: false,
    columnMinWidth: 48,
    columnMaxWidthPixels: 240,
    columnMaxWidthPercent: 50,
    drillDownMode: 'All',
    useDrillDownColumn: true,
    selectionMode: 'Multiple',
    itemsPerPage: 20,
    drilldownIcon: 'chevronright',
    useCrossLinks: false,
    showDelete: false,
    maxVisibleRowActions: 2,
    showPopupEditor: false,
    showPopupCreate: false,
    showDrillDownEditor: false,
    exportHiddenColumns: false,
    showXmlExport: true,
    useAggregation: true,
    showInlineLayoutSelector: false,
    showInlineCaption: false,
    useRowVirtualization: false,
    useInlineEditing: false,
    showInlineEditingCreateButton: true,
    inlineEditRecordPosition: 'viewportTop'
  };
}
