import { inject, injectable } from 'inversify';
import { IBookmarkActionsVisibilityService, TBookmarkActionsVisible } from './IBookmarkActionsVisibilityService';
import { IBookmarkActionsAspect } from '../aspects';
import { IBookmark } from './IBookmark';
import { isIObjectBookmark, isNewObjectBookmark } from './IObjectBookmark';
import { isIRefreshableBookmark } from './IRefreshableBookmark';
import { isIListBookmark } from './IListBookmark';
import { IAuthorization, IAuthorizationService, QuinoCoreServiceSymbols } from '@quino/core';

@injectable()
export class BookmarkActionsVisibilityService implements IBookmarkActionsVisibilityService {
  constructor(@inject(QuinoCoreServiceSymbols.IAuthorizationService) private readonly authorizationService: IAuthorizationService) {}

  getVisibility(bookmark: IBookmark, actionOptions?: IBookmarkActionsAspect | null): TBookmarkActionsVisible {
    const isNew = isNewObjectBookmark(bookmark);
    const isObjectBookmark = isIObjectBookmark(bookmark);
    const isListBookmark = isIListBookmark(bookmark);
    const isRefreshableBookmark = isIRefreshableBookmark(bookmark);

    const hasChanges = isObjectBookmark && bookmark.hasChanges();
    const isExecuting = isObjectBookmark && bookmark.isExecuting();

    const hideSave = actionOptions?.hideSave;
    const hideDelete = actionOptions?.hideDelete;
    const hideCreate = actionOptions?.hideCreate;
    const hideRefresh = actionOptions?.hideRefresh;

    let authorization: IAuthorization;
    if (isObjectBookmark) {
      authorization = this.authorizationService.getAuthorization(bookmark.genericObject.metaClass);
    } else if (isListBookmark) {
      authorization = this.authorizationService.getAuthorization(bookmark.metaClass.name);
    } else {
      return {
        refresh: false,
        saveAndNew: false,
        discard: false,
        save: false,
        create: false,
        delete: false
      };
    }

    const canCreate = authorization.canCreate();
    const canDelete = authorization.canDelete();
    const canUpdate = authorization.canUpdate();

    return {
      refresh: isRefreshableBookmark && !hideRefresh && !isNew,
      saveAndNew: !hideSave && (canUpdate || canCreate) && isNew,
      discard: !isExecuting && (isNew || (!isNew && hasChanges)),
      save: !hideSave && (canUpdate || (canCreate && isNew)),
      create: !hideCreate && canCreate,
      delete: !hideDelete && canDelete && !isNew
    };
  }
}
