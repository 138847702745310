import { INavigationService } from './INavigationService';
import { inject, injectable } from 'inversify';
import { IBookmark, isIRefreshableBookmark, isITrackableBookmark, isStateFullBookmark } from '../bookmarks';
import { QuinoUIServiceSymbols } from '../ioc';
import { ILoadingFeedback, ILoadingFeedbackSymbol, ILogger, QuinoCoreServiceSymbols } from '@quino/core';
import { IBookmarkTitleCalculator, IBookmarkTitleCalculatorSymbol } from './IBookmarkTitleCalculator';
import { IPendingChangesSetting } from '../settings';
import { IBookmarkSerializerProvider, IBookmarkSerializerProviderSymbol } from './IBookmarkSerializerProvider';
import { PopupContext } from '../components/QuinoPopup';
import { IPendingChangesService, IPendingChangesServiceSymbol } from './IPendingChangesService';
import { IPagingContextService, IPagingContextServiceSymbol } from '../context/IPagingContextService';

@injectable()
export class NavigationService implements INavigationService {
  constructor(
    @inject(IBookmarkTitleCalculatorSymbol) private readonly titleCalculator: IBookmarkTitleCalculator,
    @inject(IBookmarkSerializerProviderSymbol) private readonly bookmarkSerializerProvider: IBookmarkSerializerProvider,
    @inject(ILoadingFeedbackSymbol) private readonly loadingFeedback: ILoadingFeedback,
    @inject(IPendingChangesServiceSymbol) private readonly pendingChangesService: IPendingChangesService,
    @inject(QuinoUIServiceSymbols.IPendingChangesSetting) private readonly pendingChangesSetting: IPendingChangesSetting,
    @inject(IPagingContextServiceSymbol) private readonly pagingContextService: IPagingContextService,
    @inject(QuinoCoreServiceSymbols.ILogger) private readonly logger: ILogger
  ) {
    window.onpopstate = this.onNavigateBack;
  }

  get active(): IBookmark {
    return this.bookmarks[this.bookmarks.length - 1];
  }

  get = (): IBookmark[] => {
    return this.bookmarks;
  };

  pop = async (refreshAfterPop?: boolean): Promise<boolean> => {
    if (!(await this.confirmLeaveCurrentBookmark())) {
      return false;
    }
    this.bookmarks.pop();
    return this.set(this.bookmarks, refreshAfterPop);
  };

  peek = (): IBookmark => {
    return PopupContext.bookmarks.length > 0 ? PopupContext.bookmarks[PopupContext.bookmarks.length - 1] : this.active;
  };

  push = async (bookmark: IBookmark): Promise<boolean> => {
    if (!(await this.confirmLeaveCurrentBookmark())) {
      return false;
    }

    if (bookmark && !this.bookmarks.find((x) => x === bookmark)) {
      this.bookmarks.push(bookmark);
      this.triggerOnChange(bookmark);

      window.history.pushState(this.extractState(), this.titleCalculator.generate(bookmark), this.extractUrl(bookmark));
    }
    return true;
  };

  replaceCurrent = async (bookmark: IBookmark): Promise<boolean> => {
    if (!(await this.confirmLeaveCurrentBookmark())) {
      return false;
    }

    if (bookmark) {
      this.bookmarks.pop();
      this.bookmarks.push(bookmark);
      this.triggerOnChange(bookmark);

      window.history.replaceState(this.extractState(), this.titleCalculator.generate(bookmark), this.extractUrl(bookmark));
    }

    return true;
  };

  set = async (bookmarks: IBookmark[], refreshAfterSet?: boolean): Promise<boolean> => {
    if (!(await this.confirmLeaveCurrentBookmark())) {
      return false;
    }

    this.bookmarks = bookmarks;
    const bookmark = this.bookmarks[this.bookmarks.length - 1];
    this.triggerOnChange(bookmark);

    window.history.pushState(this.extractState(), this.titleCalculator.generate(bookmark), this.extractUrl(bookmark));
    this.shouldRefreshBookmark(refreshAfterSet);
    return true;
  };

  registerOnChange = (callback: (bookmark: IBookmark) => void): Symbol => {
    const symbol = Symbol();
    this.changeHandlers.set(symbol, callback);

    return symbol;
  };

  removeOnChange(symbol: Symbol): void {
    this.changeHandlers.delete(symbol);
  }

  extractUrl = (bookmark: IBookmark): string => {
    const instances = this.bookmarkSerializerProvider.getInstances();
    const serializer = instances.find((x) => x.canSerialize(bookmark));

    if (serializer) {
      return serializer.serialize(bookmark);
    }

    return document.location.href;
  };

  private pushStateBookmarkStateChange(bookmark: IBookmark) {
    window.history.replaceState(this.extractState(), this.titleCalculator.generate(bookmark), this.extractUrl(bookmark));
  }

  private readonly triggerOnChange = (bookmark: IBookmark) => {
    if (this.symbol && isStateFullBookmark(this.activeBookmark)) {
      this.activeBookmark.unsubscribeFromStateChange(this.symbol);
    }
    if (isStateFullBookmark(bookmark)) {
      this.symbol = bookmark.subscribeToStateChange(() => this.pushStateBookmarkStateChange(bookmark));
    }

    this.activeBookmark = bookmark;
    this.subscribeToChangeTracking(bookmark);
    this.changeHandlers.forEach((value) => value(bookmark));

    this.pagingContextService.reset();
  };

  private readonly onNavigateBack = async (event: PopStateEvent) => {
    if (!(await this.confirmLeaveCurrentBookmark())) {
      window.history.replaceState(this.extractState(), this.titleCalculator.generate(this.active), this.extractUrl(this.active));
      return;
    }

    if (event.type === 'popstate' && event.state) {
      const unload = this.loadingFeedback.load();

      this.restoreState(event.state)
        .then((bookmarks) => {
          this.bookmarks = bookmarks;
          const bookmark = this.bookmarks[this.bookmarks.length - 1];
          this.triggerOnChange(bookmark);
        })
        .catch((e) => {
          const message = 'Error while restoring Navigation state';
          this.logger.logError(`${message} ${JSON.stringify(e)}`);
        })
        .finally(unload);
      event.preventDefault();
    }
  };

  private readonly restoreState = async (state: any): Promise<IBookmark[]> => {
    const result: IBookmark[] = [];
    const serializers = this.bookmarkSerializerProvider.getInstances();
    for (const bookmarkState of state) {
      if (typeof bookmarkState === 'string') {
        const serializer = serializers.find((x) => x.canDeserialize(bookmarkState));
        if (serializer != null) {
          result.push(await serializer.deserialize(bookmarkState));
        }
      }
    }

    return result;
  };

  private readonly extractState = (): string[] => {
    const result: string[] = [];
    const serializers = this.bookmarkSerializerProvider.getInstances();
    for (const bookmark of this.bookmarks) {
      const serializer = serializers.find((x) => x.canSerialize(bookmark));
      if (serializer != null) {
        result.push(serializer.serialize(bookmark));
      }
    }

    return result;
  };

  private readonly confirmLeaveCurrentBookmark = async (): Promise<boolean> => {
    const activeBookmark = (PopupContext.bookmarks.length > 0 && PopupContext.bookmarks[PopupContext.bookmarks.length - 1]) || this.active;
    const canLeave = await this.pendingChangesService.getLeavePermission(activeBookmark, PopupContext.bookmarks.length > 0);
    if (canLeave) {
      PopupContext.bookmarks = [];
    }
    return canLeave;
  };

  private readonly subscribeToChangeTracking = (bookmark: IBookmark) => {
    if (isITrackableBookmark(bookmark) && this.pendingChangesSetting) {
      window.onbeforeunload = () => {
        return bookmark.hasChanges() ? 'pending changes' : null;
      };
    }
  };

  private shouldRefreshBookmark(shouldRefresh?: boolean) {
    if (shouldRefresh) {
      isIRefreshableBookmark(this.active) && this.active.refresh();
    }
  }

  private readonly changeHandlers = new Map<Symbol, (bookmark: IBookmark) => void>();
  private bookmarks: IBookmark[] = [];
  private activeBookmark: IBookmark;
  private symbol: Symbol;
}
