import React from 'react';
import { useService } from '../../ioc';
import { IMarkdownConverter, IMarkdownConverterSymbol } from '../../rendering';

export interface IQuinoPopupSimpleTextProps {
  text: string;
}

export const QuinoPopupSimpleText = (props: IQuinoPopupSimpleTextProps) => {
  const markdownConverter = useService<IMarkdownConverter>(IMarkdownConverterSymbol);

  return (
    <p className={'quino-popup-simple-text'}>
      {(markdownConverter.isMarkdown(props.text) && (
        <div className={'quino-markdown'} dangerouslySetInnerHTML={{ __html: markdownConverter.convertToHtml(`${props.text}`) }} />
      )) ||
        props.text}
    </p>
  );
};
