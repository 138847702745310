import React from 'react';
import ReactDOM from 'react-dom';
import { IRenderer } from './IRenderer';
import { inject, injectable } from 'inversify';
import { IApplication, QuinoCoreServiceSymbols } from '@quino/core';
import { ContainerContext } from '../ioc';

@injectable()
export class Renderer implements IRenderer {
  constructor(@inject(QuinoCoreServiceSymbols.IApplication) private readonly container: IApplication) {}

  inject = (component: any): (() => void) => {
    const element = document.createElement('div');
    element.style.width = '0px';
    element.style.height = '0px';
    element.style.position = 'absolute';
    document.body.appendChild(element);

    ReactDOM.render(<ContainerContext.Provider value={{ container: this.container }}>{component}</ContainerContext.Provider>, element);

    return () => {
      ReactDOM.unmountComponentAtNode(element);
      document.body.removeChild(element);
    };
  };
}
