import { IMetaElement } from '@quino/core';
import { useService } from '../../ioc/hook/useService';
import { ICheckboxConfiguration, ICheckboxConfigurationSymbol } from './ICheckboxConfiguration';
import { usePromise } from '../../components/Util/';
import { ICheckboxConfigurationService, ICheckboxConfigurationServiceSymbol } from './ICheckboxConfigurationService';

export function useCheckboxConfiguration(element: IMetaElement): ICheckboxConfiguration {
  const checkboxConfigurationService = useService<ICheckboxConfigurationService>(ICheckboxConfigurationServiceSymbol);

  const defaultConfiguration = useService<ICheckboxConfiguration>(ICheckboxConfigurationSymbol);
  const [configuration] = usePromise<ICheckboxConfiguration, any>(async () => checkboxConfigurationService.getCheckboxConfiguration(element), []);

  return configuration ? configuration : defaultConfiguration;
}
