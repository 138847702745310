import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  IDynamicStringCalculator,
  IDynamicStringCalculatorSymbol,
  IGenericObject,
  IMetaRelation,
  ITranslationService,
  ITranslationServiceSymbol
} from '@quino/core';
import { useService } from '../../ioc/hook';
import { IQuinoDataGridProps, QuinoDataGrid } from './QuinoDataGrid';
import { Popover } from 'devextreme-react/popover';
import { QuinoUIServiceSymbols } from '../../ioc';
import { IQuinoDataGridConfigurationService } from './IQuinoDataGridConfigurationService';
import { IListBookmark, isILayoutAwareBookmark, useOnSourceChange } from '../../bookmarks';
import { IQuinoDataGridConfiguration } from './IQuinoDataGridConfiguration';
import { IQuinoMetaPanelActions } from '../Types';
import { IFilterState } from './useFilterButton';

export interface IQuinoMetaDataGridProps {
  bookmark: IListBookmark;
  visible: boolean;
  drilldown: (target: IGenericObject, context?: any) => void;
  gridProperties?: Partial<IQuinoDataGridProps>;
  metaRelation?: IMetaRelation;
  onFilterChange?: (newState: IFilterState) => void;
}

export type TQuinoMetaDataGridState = {
  isLoading: boolean;
  hasError: boolean;
};

export function QuinoMetaDataGrid(props: IQuinoMetaDataGridProps) {
  const { bookmark, visible, drilldown } = props;

  const actions = useService<IQuinoMetaPanelActions>(QuinoUIServiceSymbols.IQuinoMetaPanelActions);
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const metaDataGridConfigurationService = useService<IQuinoDataGridConfigurationService>(QuinoUIServiceSymbols.IQuinoDataGridConfigurationService);
  const dynamicStringCalculator = useService<IDynamicStringCalculator>(IDynamicStringCalculatorSymbol);

  const dataGridConfiguration = metaDataGridConfigurationService.getMetaDataGridConfiguration(bookmark.layout, bookmark.metaClass);
  const gridOptions: IQuinoDataGridConfiguration = useMemo(() => {
    return { ...dataGridConfiguration, ...props.gridProperties };
  }, [dataGridConfiguration, props.gridProperties]);
  const defaultGroupColumnName = metaDataGridConfigurationService.getDefaultGroupColumnName(bookmark.layout, props.metaRelation);

  useOnSourceChange(bookmark);

  const [state, dispatch] = useState<TQuinoMetaDataGridState>({
    isLoading: false,
    hasError: false
  });

  const description = useMemo(() => {
    return dynamicStringCalculator.calculateDescription(bookmark.layout, {});
  }, [bookmark.layout, dynamicStringCalculator]);

  const deleteCommand = (target: IGenericObject | IGenericObject[]) => {
    actions
      .delete(target)
      .then(() => {
        bookmark.reloadDataSource();
      })
      .catch(() =>
        dispatch({
          ...state,
          hasError: true
        })
      );
  };

  if (!gridOptions) {
    return <div />;
  }

  return (
    <div className={'quino-ecui-meta-data-grid-wrapper'}>
      {description && <div className={'quino-ecui-data-grid-description'}>{description}</div>}
      <QuinoDataGrid
        key={'DataGrid_' + bookmark.metaClass.name}
        className={`quino-ecui-meta-data-grid ${props.gridProperties?.className || ''}`}
        stateStorageKey={bookmark.options.storageKey}
        {...gridOptions}
        headerActions={[]}
        id={bookmark.metaClass.name}
        source={bookmark.dataSource}
        layout={bookmark.layout ? bookmark.layout : undefined}
        metaClass={bookmark.metaClass}
        isLoading={false}
        visible={visible}
        bookmark={bookmark}
        metaRelation={props.metaRelation}
        onSelectionChanged={(selection) => {
          bookmark.selection = selection;
          if (props.gridProperties && props.gridProperties.onSelectionChanged) {
            props.gridProperties.onSelectionChanged(selection);
          }
        }}
        rowActions={[]}
        actions={{
          drilldown: drilldown,
          delete: deleteCommand
        }}
        defaultGroupColumn={defaultGroupColumnName}
        onFilterChange={props.onFilterChange}
      />
      <Popover
        key={'DataGrid_Popover_' + bookmark.metaClass.name}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showCloseButton={false}
        showTitle={false}
        shading={false}
        height={100}
        width={300}
        fullScreen={false}
        title={'Error'}
        position={{
          at: 'center',
          my: 'center',
          of: '#QuinoDataGrid' + bookmark.metaClass.name
        }}>
        <p>{translationService.translate('List.ErrorCouldNotLoadData')}</p>
      </Popover>
    </div>
  );
}
