import { ITitleCalculator } from './ITitleCalculator';
import { inject, injectable } from 'inversify';
import {
  getAspect,
  getElementPath,
  IMetaProperty,
  isIMetaProperty,
  isMetaRelation,
  isValueList,
  IValueListAspect,
  LayoutType,
  ValueListAspectIdentifier
} from '../meta';
import { ILayoutResolver, ILayoutResolverSymbol } from '../scoping';
import { IMetaPropertyValueService, IMetaPropertyValueServiceSymbol } from './IMetaPropertyValueService';
import { IGenericObject } from './IGenericObject';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IMetadataTree } from '../api';
import { IValueFormatter, IValueFormatterSymbol } from './IValueFormatter';
import { IVisibleCalculator } from '../expressions';

@injectable()
export class TitleCalculator implements ITitleCalculator {
  constructor(
    @inject(ILayoutResolverSymbol) private readonly layoutResolver: ILayoutResolver,
    @inject(QuinoCoreServiceSymbols.IMetadataTree) private readonly metadataTree: IMetadataTree,
    @inject(IMetaPropertyValueServiceSymbol) private readonly valueService: IMetaPropertyValueService,
    @inject(IValueFormatterSymbol) private readonly valueFormatter: IValueFormatter,
    @inject(QuinoCoreServiceSymbols.IVisibleCalculator) private readonly visibleCalculator: IVisibleCalculator
  ) {}

  generate(obj: any, metaClass: string, customTitleLayout?: string): string {
    const customLayout = customTitleLayout
      ? this.metadataTree.getLayouts(metaClass).find((l) => l.type === LayoutType.Title && l.name.toLowerCase() === customTitleLayout.toLowerCase())
      : undefined;
    const layout = customLayout ?? this.layoutResolver.tryResolveSingle({ metaClass: metaClass, type: LayoutType.Title });
    let result = '';
    if (!obj || !layout) {
      return '';
    } else if (this.isNewElement(obj, metaClass)) {
      return this.metadataTree.getClass(metaClass).caption;
    }

    for (const element of layout.elements) {
      const visible = this.visibleCalculator.calculate(element, obj);
      if (visible)
        if (isMetaRelation(element) || (isValueList(element) && isIMetaProperty(element))) {
          result += `${this.getTitle(element, obj)} ${this.delimiter} `;
        } else if (isIMetaProperty(element)) {
          if (element.path.includes('.')) {
            result += `${this.getTitle(element, obj)} ${this.delimiter} `;
          } else if (obj[getElementPath(element)]) {
            const value = obj[getElementPath(element)];
            const formattedValue = this.valueFormatter.formatValue(element, value);

            result += `${formattedValue} ${this.delimiter} `;
          }
        }
    }

    return result.substring(0, result.length - 2);
  }

  getTitle(metaProperty: IMetaProperty, genericObject: IGenericObject): string {
    if (metaProperty.path.includes('.')) {
      const target = this.valueService.getRelatedPropertyData(metaProperty, genericObject);
      return target ? this.getTitle(target.metaProperty, target.genericObject) : '';
    }

    if (isValueList(metaProperty)) {
      const aspect = getAspect<IValueListAspect>(metaProperty, ValueListAspectIdentifier);
      const value = isMetaRelation(metaProperty)
        ? genericObject[metaProperty.sourceProperties[0]]
        : this.valueService.getPlainValue(metaProperty, genericObject);
      const result = aspect.options.find((x) => x.Value === value);
      return result ? result.Caption : '';
    } else if (isMetaRelation(metaProperty)) {
      return this.generate(this.valueService.getPlainValue(metaProperty, genericObject), metaProperty.targetClass);
    } else {
      return this.valueFormatter.formatValue(metaProperty, this.valueService.getPlainValue(metaProperty, genericObject));
    }
  }

  delimiter = '/';

  private readonly isNewElement = (obj: any, metaClassName: string): boolean => {
    const metaClass = this.metadataTree.getClass(metaClassName);
    for (const key of metaClass.primaryKey) {
      if (obj != null && obj[key] != null && obj[key] !== -1 && obj[key] !== '00000000-0000-0000-0000-000000000000') {
        return false;
      }
    }

    return true;
  };
}
