import { DataType } from './DataType';
import { IMetaClass } from './IMetaClass';
import { IMetaProperty } from './IMetaProperty';

export const getPropertyFromClass = (metaClass: IMetaClass, propertyName: string): IMetaProperty | undefined => {
  return metaClass.properties.find((p) => p.name.toLowerCase() === propertyName.toLowerCase());
};

export const isGuidProperty = (metaClass: IMetaClass, propertyName: string): boolean => {
  return getPropertyFromClass(metaClass, propertyName)?.dataType === DataType.Guid;
};
