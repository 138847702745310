import { IMetaAspect } from '@quino/core';

/**
 * The identifier for retrieving an GroupingAspect.
 */
export const GroupingAspectIdentifier = 'GroupingAspect';

/**
 * GroupingAspect containing the column to group by default.
 */
export interface IGroupingAspect extends IMetaAspect {
  columnName: string;
}
