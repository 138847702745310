import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  DataType,
  IDynamicStringCalculator,
  IDynamicStringCalculatorSymbol,
  IMetadataTree,
  IMetaProperty,
  IMetaRelation,
  isIMetaAction,
  isValueList,
  MetaCardinality,
  QuinoCoreServiceSymbols
} from '@quino/core';
import { useService } from '../../ioc';
import { SelectBox } from 'devextreme-react/select-box';

export interface IQuinoMetaPropertySelectorProps {
  metaClass?: string;
  value?: string;
  onSelect: (value?: IMetaProperty) => void;
  excludeActionProperties?: boolean;
  excludeNonValueListRelations?: boolean;
  excludeNonNumericProperties?: boolean;
  excludeRelationPrimaryProperties?: boolean;
  excludeMultipleCardinalityRelations?: boolean;
}

export function QuinoMetaPropertySelector(props: IQuinoMetaPropertySelectorProps) {
  const metadataTree = useService<IMetadataTree>(QuinoCoreServiceSymbols.IMetadataTree);
  const dynamicStringCalculator = useService<IDynamicStringCalculator>(IDynamicStringCalculatorSymbol);

  const [value, setValue] = useState<IMetaProperty | undefined>(() => {
    if (props.metaClass) {
      const metaClass = metadataTree.getClass(props.metaClass);
      return metaClass.relations.find((x) => x.name === props.value) || metaClass.properties.find((x) => x.name === props.value);
    }
    return undefined;
  });

  const isNumericOrBoolean = useCallback((property: IMetaProperty) => {
    switch (property.dataType) {
      case DataType.Boolean:
      case DataType.Currency:
      case DataType.Double:
      case DataType.Integer:
      case DataType.LargeInteger:
      case DataType.SmallInteger:
      case DataType.TinyInteger:
        return true;
      default:
        return false;
    }
  }, []);

  const source: IMetaProperty[] = useMemo(() => {
    if (props.metaClass) {
      const metaClass = metadataTree.getClass(props.metaClass);

      const relations: { property: IMetaRelation; caption: string }[] = [];
      metaClass.relations.forEach((r) => {
        if (
          !props.excludeNonNumericProperties &&
          !(props.excludeMultipleCardinalityRelations && r.cardinality === MetaCardinality.Multiple) &&
          !(props.excludeNonValueListRelations && !isValueList(r))
        ) {
          relations.push({ property: r, caption: dynamicStringCalculator.calculateCaption(r) });
        }
      });
      const relationSourceProps = relations.map((r) => r.property.sourceProperties[0]);

      const properties: { property: IMetaProperty; caption: string }[] = [];
      metaClass.properties.forEach((p) => {
        if (
          !(props.excludeActionProperties && isIMetaAction(p)) &&
          !(props.excludeNonNumericProperties && !isNumericOrBoolean(p)) &&
          !(props.excludeRelationPrimaryProperties && relationSourceProps.includes(p.name))
        ) {
          properties.push({ property: p, caption: dynamicStringCalculator.calculateCaption(p) });
        }
      });

      return [...properties, ...relations].sort((a, b) => a.caption.localeCompare(b.caption)).map((i) => i.property);
    }

    return [];
  }, [
    props.metaClass,
    props.excludeNonNumericProperties,
    props.excludeMultipleCardinalityRelations,
    props.excludeNonValueListRelations,
    props.excludeActionProperties,
    props.excludeRelationPrimaryProperties,
    metadataTree,
    dynamicStringCalculator,
    isNumericOrBoolean
  ]);

  return (
    <SelectBox
      deferRendering={false}
      showClearButton={true}
      displayExpr={(data: any) => data && `${data.caption} (${data.name})`}
      value={value}
      dataSource={source}
      searchEnabled={true}
      dropDownOptions={{ width: '320px' }}
      onValueChanged={(e) => {
        setValue(e.value);
        props.onSelect(e.value);
      }}
    />
  );
}
