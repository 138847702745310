import {
  FrameworkStartupGroup,
  IApplication,
  ILoadingFeedback,
  ILoadingFeedbackSymbol,
  InitializationGroup,
  IODataBatchRequestFactory,
  IODataBatchRequestFactorySymbol,
  ODataBatchRequestFactory
} from '@quino/core';
import { QuinoUIServiceSymbols } from '../ioc';
import { IValidationResultService } from '../validations/IValidationResultService';
import { ValidationResultService } from '../validations/ValidationResultService';
import {
  defaultCheckboxConfiguration,
  DefaultCheckboxConfigurationService,
  DefaultPendingChangesSetting,
  ICheckboxConfiguration,
  ICheckboxConfigurationService,
  ICheckboxConfigurationServiceSymbol,
  ICheckboxConfigurationSymbol,
  IPendingChangesSetting
} from '../settings';
import {
  AbsoluteNavigationLinkResolver,
  AbsoluteNavigationLinkResolverSymbol,
  BookmarkTitleCalculator,
  ClassNavigationLinkResolver,
  ClassNavigationLinkResolverSymbol,
  HomeNavigationService,
  IBookmarkTitleCalculator,
  IBookmarkTitleCalculatorSymbol,
  IHomeNavigationService,
  IHomeNavigationServiceSymbol,
  INavigationLinkResolver,
  INavigationLinkResolverProvider,
  INavigationLinkResolverProviderSymbol,
  INavigationLinkService,
  INavigationLinkServiceSymbol,
  INavigationService,
  INavigationServiceSymbol,
  IPendingChangesService,
  IPendingChangesServiceSymbol,
  LoadingFeedback,
  NavigationLinkResolverProvider,
  NavigationService,
  ObjectNavigationLinkResolver,
  ObjectNavigationLinkResolverSymbol,
  PendingChangesService,
  StateFullBookmarkSerializer
} from '../navigation';
import { Converter } from 'showdown';
import { IODataSourceFactory, IODataSourceFactorySymbol } from '../data/IODataSourceFactory';
import { ODataSourceFactory } from '../data/ODataSourceFactory';
import { IQuinoDataGridColumnFactory, IQuinoDataGridColumnFactorySymbol } from '../components/QuinoDataGrid/IQuinoDataGridColumnFactory';
import { QuinoDataGridColumnFactory } from '../components/QuinoDataGrid/QuinoDataGridColumnFactory';
import { IValueListTools, IValueListToolsSymbol } from '../data/IValueListTools';
import { ValueListTools } from '../data/ValueListTools';
import { IHighlightTextService, IHighlightTextServiceSymbol } from '../components/Util/HighlightTextService/IHighlightTextService';
import { HighlightTextService } from '../components/Util/HighlightTextService/HighlightTextService';
import { IClientActionProvider, IClientActionProviderSymbol } from '../actions/IClientActionProvider';
import { ClientActionProvider } from '../actions/ClientActionProvider';
import { ChangePasswordAction, ChangePasswordActionSymbol } from '../identity/ChangePasswordAction';
import {
  DataGridSettingsService,
  IDataGridSettingsService,
  IDataGridSettingsServiceSymbol,
  IQuinoDataGridExporter,
  IQuinoDataGridExporterSymbol,
  QuinoDataGridExporter
} from '../components';
import { IBookmarkSerializerProvider, IBookmarkSerializerProviderSymbol } from '../navigation/IBookmarkSerializerProvider';
import { BookmarkSerializerProvider } from '../navigation/BookmarkSerializerProvider';
import { ListBookmarkSerializer, ListBookmarkSerializerSymbol } from '../navigation/ListBookmarkSerializer';
import { ObjectBookmarkSerializer, ObjectBookmarkSerializerSymbol } from '../navigation/ObjectBookmarkSerializer';
import { DashboardBookmarkSerializer, DashboardBookmarkSerializerSymbol } from '../navigation/DashboardBookmarkSerializer';
import { IMarkdownConverter, IMarkdownConverterSymbol } from './MarkdownConverter/IMarkdownConverter';
import { RelatedListBookmarkSerializer, RelatedListBookmarkSerializerSymbol } from '../navigation/RelatedListBookmarkSerializer';
import {
  DataSourceFactoryTools,
  IDataSourceFactoryTools,
  IDataSourceFactoryToolsSymbol,
  IGenericObjectPersistenceService,
  GenericObjectPersistenceService
} from '../data';
import {
  IPagingContextService,
  IPagingContextServiceSymbol,
  IQuinoContextInitializerService,
  IQuinoContextInitializerServiceSymbol,
  IQuinoContextUrlService,
  IQuinoContextUrlServiceSymbol,
  PagingContextService,
  QuinoContextInitializerService,
  QuinoContextUrlService
} from '../context';
import { IGenericObjectPersistenceServiceSymbol } from '../data/IGenericObjectPersistenceService';
import { BookmarkTrackChangesService, InitialBookmarkService, IBookmarkTrackChangesService, IInitialBookmarkService } from '../bookmarks';
import {
  IClientAction,
  ILayoutActionsService,
  IQuinoActionFactory,
  IQuinoActionFactorySymbol,
  IStandardBookmarkActionsService,
  LayoutActionService,
  LayoutActionsServiceSymbol,
  QuinoActionFactory,
  StandardBookmarkActionsService,
  StandardBookmarkActionsServiceSymbol
} from '../actions';
import { OidcBookmarkSerializer, OidcBookmarkSerializerSymbol } from '../navigation/OidcBookmarkSerializer';
import {
  IResponsivityBreakpointService,
  IResponsivityBreakpointServiceSymbol,
  IResponsivityService,
  IResponsivityServiceSymbol,
  ResponsivityBreakpointService,
  ResponsivityService
} from '../responsivity';
import { INamespaceProvider, NamespaceProviderSymbol } from '@quino/core';
import { BreakpointNamespace, BreakpointNamespaceSymbol } from '../expression/BreakpointNamespace';
import { NavigationLinkService } from '../navigation/NavigationLinks/NavigationLinkService';
import { CalendarBookmarkSerializer, CalendarBookmarkSerializerSymbol } from '../navigation/CalendarBookmarkSerializer';
import {
  defaultCalendarConfiguration,
  ICalendarConfiguration,
  ICalendarConfigurationService,
  ICalendarConfigurationServiceSymbol,
  ICalendarConfigurationSymbol,
  ICalendarDataService,
  ICalendarDataServiceSymbol
} from '../calendar';
import { CalendarConfigurationService } from '../calendar/configuration/CalendarConfigurationService';
import { CalendarDataService } from '../calendar/service/CalendarDataService';
import { URL, URLSearchParams } from '@cliqz/url-parser';
import { IFeedbackService, IFeedbackServiceSymbol } from '../feedback';
import { ClassActionsService } from '../actions/ClassActionsService';
import { ClassActionsServiceSymbol, IClassActionsService } from '../actions/IClassActionsService';
import { IBookmarkActionsVisibilityService, IBookmarkActionsVisibilityServiceSymbol } from '../bookmarks/IBookmarkActionsVisibilityService';
import { BookmarkActionsVisibilityService } from '../bookmarks/BookmarkActionsVisibilityService';
import { StateFullBookmarkSerializerSymbol } from '../navigation/StateFullBookmarkSerializer';
import { IRenderer, IRendererSymbol } from './IRenderer';
import { Renderer } from './Renderer';
import { ComponentMappingProvider, ComponentRegistry, IComponentMappingProvider, IComponentRegistry } from './ComponentRegistry';
import { MarkdownConverter } from './MarkdownConverter';
import { GlobalHelpTextVisibilityStrategy, HelpTextVisibility, IHelpTextVisibilityStrategy } from './HelpTexts';
import { ThisNamespace, ThisNamespaceSymbol } from '../expression/ThisNamespace';

export class UIModule {
  static use = (application: IApplication): void => {
    application.registerSingle<IBookmarkActionsVisibilityService>(IBookmarkActionsVisibilityServiceSymbol, BookmarkActionsVisibilityService);
    application.registerSingle<IQuinoActionFactory>(IQuinoActionFactorySymbol, QuinoActionFactory);
    application.registerSingle<IRenderer>(IRendererSymbol, Renderer);
    application.registerSingle<IClientActionProvider>(IClientActionProviderSymbol, ClientActionProvider);
    application.registerSingle<INavigationService>(INavigationServiceSymbol, NavigationService);
    application.registerSingle<IComponentMappingProvider>(QuinoUIServiceSymbols.IComponentMappingProvider, ComponentMappingProvider);
    application.registerSingle<IComponentRegistry>(QuinoUIServiceSymbols.IComponentRegistry, ComponentRegistry);
    application.registerSingle<IValidationResultService>(QuinoUIServiceSymbols.IValidationResultService, ValidationResultService);
    application.registerSingle<IBookmarkTrackChangesService>(QuinoUIServiceSymbols.IBookmarkTrackChangesService, BookmarkTrackChangesService);
    application.registerSingle<IInitialBookmarkService>(QuinoUIServiceSymbols.IInitialBookmarkService, InitialBookmarkService);
    application.registerSingle<IPendingChangesService>(IPendingChangesServiceSymbol, PendingChangesService);
    application.registerSingle<IPendingChangesSetting>(QuinoUIServiceSymbols.IPendingChangesSetting, DefaultPendingChangesSetting);
    application.registerSingle<ILoadingFeedback>(ILoadingFeedbackSymbol, LoadingFeedback);
    application.registerSingle<IMarkdownConverter>(IMarkdownConverterSymbol, MarkdownConverter);
    application.registerSingle<IValueListTools>(IValueListToolsSymbol, ValueListTools);
    application.registerSingle<IDataSourceFactoryTools>(IDataSourceFactoryToolsSymbol, DataSourceFactoryTools);
    application.registerSingle<IODataSourceFactory>(IODataSourceFactorySymbol, ODataSourceFactory);
    application.registerSingle<IODataBatchRequestFactory>(IODataBatchRequestFactorySymbol, ODataBatchRequestFactory);
    application.registerSingle<IQuinoDataGridColumnFactory>(IQuinoDataGridColumnFactorySymbol, QuinoDataGridColumnFactory);
    application.registerSingle<IDataGridSettingsService>(IDataGridSettingsServiceSymbol, DataGridSettingsService);
    application.registerSingle<IQuinoDataGridExporter>(IQuinoDataGridExporterSymbol, QuinoDataGridExporter);
    application.registerSingle<IHighlightTextService>(IHighlightTextServiceSymbol, HighlightTextService);
    application.registerSingle<IBookmarkTitleCalculator>(IBookmarkTitleCalculatorSymbol, BookmarkTitleCalculator);
    application.registerSingle<IBookmarkSerializerProvider>(IBookmarkSerializerProviderSymbol, BookmarkSerializerProvider);
    application.registerSingle<RelatedListBookmarkSerializer>(RelatedListBookmarkSerializerSymbol, RelatedListBookmarkSerializer);
    application.registerSingle<ListBookmarkSerializer>(ListBookmarkSerializerSymbol, ListBookmarkSerializer);
    application.registerSingle<ObjectBookmarkSerializer>(ObjectBookmarkSerializerSymbol, ObjectBookmarkSerializer);
    application.registerSingle<StateFullBookmarkSerializer>(StateFullBookmarkSerializerSymbol, StateFullBookmarkSerializer);
    application.registerSingle<DashboardBookmarkSerializer>(DashboardBookmarkSerializerSymbol, DashboardBookmarkSerializer);
    application.registerSingle<OidcBookmarkSerializer>(OidcBookmarkSerializerSymbol, OidcBookmarkSerializer);
    application.registerSingle<IQuinoContextInitializerService>(IQuinoContextInitializerServiceSymbol, QuinoContextInitializerService);
    application.registerSingle<IQuinoContextUrlService>(IQuinoContextUrlServiceSymbol, QuinoContextUrlService);
    application.registerSingle<IGenericObjectPersistenceService>(IGenericObjectPersistenceServiceSymbol, GenericObjectPersistenceService);
    application.registerSingle<IHomeNavigationService>(IHomeNavigationServiceSymbol, HomeNavigationService);
    application.registerSingle<IStandardBookmarkActionsService>(StandardBookmarkActionsServiceSymbol, StandardBookmarkActionsService);
    application.registerSingle<ILayoutActionsService>(LayoutActionsServiceSymbol, LayoutActionService);
    application.registerSingle<IClassActionsService>(ClassActionsServiceSymbol, ClassActionsService);
    application.registerSingle<IResponsivityService>(IResponsivityServiceSymbol, ResponsivityService);
    application.registerSingle<IResponsivityBreakpointService>(IResponsivityBreakpointServiceSymbol, ResponsivityBreakpointService);
    application.registerSingle<IClientAction>(ChangePasswordActionSymbol, ChangePasswordAction);
    application.registerSingle<INavigationLinkService>(INavigationLinkServiceSymbol, NavigationLinkService);
    application.registerSingle<INavigationLinkResolverProvider>(INavigationLinkResolverProviderSymbol, NavigationLinkResolverProvider);
    application.registerSingle<INavigationLinkResolver>(AbsoluteNavigationLinkResolverSymbol, AbsoluteNavigationLinkResolver);
    application.registerSingle<INavigationLinkResolver>(ClassNavigationLinkResolverSymbol, ClassNavigationLinkResolver);
    application.registerSingle<INavigationLinkResolver>(ObjectNavigationLinkResolverSymbol, ObjectNavigationLinkResolver);
    application.registerSingle<BreakpointNamespace>(BreakpointNamespaceSymbol, BreakpointNamespace);
    application.registerSingle<ThisNamespace>(ThisNamespaceSymbol, ThisNamespace);
    application.registerSingle<CalendarBookmarkSerializer>(CalendarBookmarkSerializerSymbol, CalendarBookmarkSerializer);
    application.registerConstant<ICalendarConfiguration>(ICalendarConfigurationSymbol, defaultCalendarConfiguration);
    application.registerSingle<ICalendarConfigurationService>(ICalendarConfigurationServiceSymbol, CalendarConfigurationService);
    application.registerSingle<ICalendarDataService>(ICalendarDataServiceSymbol, CalendarDataService);
    application.registerSingle<IPagingContextService>(IPagingContextServiceSymbol, PagingContextService);
    application.registerConstant<ICheckboxConfiguration>(ICheckboxConfigurationSymbol, defaultCheckboxConfiguration);
    application.registerSingle<ICheckboxConfigurationService>(ICheckboxConfigurationServiceSymbol, DefaultCheckboxConfigurationService);

    application
      .registerStartupAction(Symbol.for('UiNamespaceStartupAction'), (app) => {
        const provider = app.get<INamespaceProvider>(NamespaceProviderSymbol);
        provider.register(app.get<BreakpointNamespace>(BreakpointNamespaceSymbol), BreakpointNamespaceSymbol);
        provider.register(app.get<ThisNamespace>(ThisNamespaceSymbol), ThisNamespaceSymbol);
      })
      .moveTo(FrameworkStartupGroup);

    application
      .bind<IHelpTextVisibilityStrategy>(QuinoUIServiceSymbols.IHelpTextVisibilityStrategy)
      .toDynamicValue(() => new GlobalHelpTextVisibilityStrategy(HelpTextVisibility.Always))
      .inRequestScope();

    application
      .bind<Converter>(QuinoUIServiceSymbols.MarkdownHtmlRenderer)
      .toDynamicValue(() => new Converter())
      .inSingletonScope();

    application.registerStartupAction(Symbol.for('RegisterDefaultIdentityClientActions'), (app) => {
      const provider = app.get<IClientActionProvider>(IClientActionProviderSymbol);
      provider.register(app.get<IClientAction>(ChangePasswordActionSymbol), Symbol.for('ChangePassword'));
    });

    application.registerStartupAction(Symbol.for('RegisterDefaultBookmarkSerializers'), (app) => {
      const provider = app.get<IBookmarkSerializerProvider>(IBookmarkSerializerProviderSymbol);
      provider.register(app.get<RelatedListBookmarkSerializer>(RelatedListBookmarkSerializerSymbol), Symbol.for('RelatedListBookmark'));
      provider.register(app.get<ListBookmarkSerializer>(ListBookmarkSerializerSymbol), Symbol.for('ListBookmark'));
      provider.register(app.get<ObjectBookmarkSerializer>(ObjectBookmarkSerializerSymbol), Symbol.for('ObjectBookmark'));
      provider.register(app.get<DashboardBookmarkSerializer>(DashboardBookmarkSerializerSymbol), Symbol.for('DashboardBookmark'));
      provider.register(app.get<OidcBookmarkSerializer>(OidcBookmarkSerializerSymbol), Symbol.for('OidcBookmark'));
      provider.register(app.get<CalendarBookmarkSerializer>(CalendarBookmarkSerializerSymbol), Symbol.for('CalendarBookmark'));
    });

    application.registerStartupAction(Symbol.for('RegisterDefaultNavigationLinkResolvers'), (app) => {
      const provider = app.get<INavigationLinkResolverProvider>(INavigationLinkResolverProviderSymbol);
      provider.register(app.get<INavigationLinkResolver>(AbsoluteNavigationLinkResolverSymbol), Symbol.for('absolute'));
      provider.register(app.get<INavigationLinkResolver>(ClassNavigationLinkResolverSymbol), Symbol.for('class'));
      provider.register(app.get<INavigationLinkResolver>(ObjectNavigationLinkResolverSymbol), Symbol.for('object'));
    });

    application
      .registerStartupAction(Symbol.for('thirdParty_auth_error'), async (app) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        if (params.has('thirdParty_auth_error')) {
          const error = decodeURIComponent(params.get('thirdParty_auth_error')!);
          const feedbackService = app.get<IFeedbackService>(IFeedbackServiceSymbol);
          await feedbackService.showMessage('Error', error);
        }
      })
      .moveTo(InitializationGroup);
  };
}
