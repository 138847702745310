import { IMetaAspect } from '@quino/core';
import { ICheckboxConfiguration } from '../settings';

/**
 * The identifier for retrieving a checkbox configuration aspect.
 */
export const CheckboxConfigurationAspectIdentifier = 'CheckboxConfigurationAspect';

/**
 * A configuration aspect for the checkbox.
 */
export interface ICheckboxConfigurationAspect extends IMetaAspect {
  /**
   * The configuration available.
   */
  configuration: ICheckboxConfiguration;
}
