import { IExpressionEvaluator } from './IExpressionEvaluator';
import { IExpression } from './IExpression';
import { inject, injectable } from 'inversify';
import { isFunctionCallExpression } from './IFunctionCallExpression';
import { QuinoCoreServiceSymbols } from '../ioc';
import { INamespaceProvider, NamespaceProviderSymbol } from './namespaces';
import { GlobalNamespace } from './namespaces/GlobalNamespace';

export const FunctionCallExpressionEvaluatorSymbol = Symbol.for('FunctionCallExpressionEvaluator');

@injectable()
export class FunctionCallExpressionEvaluator implements IExpressionEvaluator {
  constructor(
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly evaluator: IExpressionEvaluator,
    @inject(NamespaceProviderSymbol) private readonly namespaces: INamespaceProvider
  ) {}

  evaluate<TValue>(expression: IExpression | TValue, context: any): TValue {
    if (isFunctionCallExpression(expression)) {
      const argList = [];
      let counter = 0;
      while (true) {
        const argument = expression['Element' + counter];
        if (argument != null) {
          argList.push(this.evaluator.evaluate(argument, context));
          counter++;
        } else {
          break;
        }
      }

      const globalNamespace = this.namespaces.getInstances().find((namespace) => namespace.name === 'Global');
      let fn = context[expression.name];

      if (fn == undefined && globalNamespace) {
        fn = globalNamespace[expression.name];
      }

      if (typeof fn == 'function') {
        return fn(...argList, context);
      } else {
        return fn;
      }
    }

    // @ts-ignore
    return expression;
  }
}
