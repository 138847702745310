import { injectable } from 'inversify';
import { INamespace } from './INamespace';
import { formatDate } from 'devextreme/localization';

export const GlobalNamespaceSymbol = Symbol.for('GlobalNamespace');

@injectable()
export class GlobalNamespace implements INamespace {
  Format = (date: unknown, format: unknown): string | undefined => {
    if (date instanceof Date && typeof format === 'string') {
      switch (format) {
        case 't':
          return formatDate(date, 'HH:mm');
        case 'T':
          return formatDate(date, 'HH:mm:ss');
        case 'd':
          return formatDate(date, 'dd.MM.yyyy');
        case 'D':
          return formatDate(date, 'EEEE, dd. MMMM yyyy');
      }
    }
    return;
  };
  ShortTimeFormat = () => 't';
  LongTimeFormat = () => 'T';
  ShortDateFormat = () => 'd';
  LongDateFormat = () => 'D';

  Today = (): Date => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  Day = (): number => {
    return new Date().getDate();
  };

  Month = (): number => {
    return new Date().getMonth() + 1;
  };

  Year = (): number => {
    return new Date().getFullYear();
  };

  Hour = (): number => {
    return new Date().getHours();
  };

  Minute = (): number => {
    return new Date().getMinutes();
  };

  Second = (): number => {
    return new Date().getSeconds();
  };

  Millisecond = (): number => {
    return new Date().getMilliseconds();
  };

  name = 'Global';
}
