import {
  DataType,
  IControlBehaviorRegistry,
  IFieldError,
  IGenericObject,
  ILogger,
  IMetaClass,
  IMetaElement,
  IMetaLayout,
  IMetaPropertyValueService,
  isIMetaAction,
  isServerValidationError,
  IValidationResult,
  IValidator,
  IVisibleCalculator
} from '@quino/core';
import { IObjectBookmark, IObjectBookmarkEvent, isNewObjectPrimaryKey, ObjectBookmarkEventType } from './IObjectBookmark';
import { StateFullBookmark } from './StateFullBookmark';
import cloneDeep from 'lodash/cloneDeep';
import { IGenericObjectPersistenceService } from '../data';
import { CONTROL_IDENTIFIER } from '../rendering';
import { IListBookmark } from './IListBookmark';

export class ObjectBookmark extends StateFullBookmark implements IObjectBookmark {
  constructor(
    public genericObject: IGenericObject,
    public layout: IMetaLayout,
    public metaClass: IMetaClass,
    reloadObject: () => Promise<IGenericObject>,
    private readonly persistenceService: IGenericObjectPersistenceService,
    private readonly validator: IValidator,
    private readonly metaPropertyValueService: IMetaPropertyValueService,
    private readonly logger: ILogger,
    private readonly controlModifiedProvider: IControlBehaviorRegistry<boolean>,
    private readonly visibleCalculator: IVisibleCalculator
  ) {
    super();
    this.originalObject = cloneDeep(genericObject);
    this.rerenderKey = genericObject.primaryKey;
    this.hasInternalChanges = false;
    this.reloadObject = reloadObject;

    this.subscribeToStateChange((scopeKey) => scopeKey === 'scope' && this.refresh());
  }

  updateFieldValue = (metaPropertyPath: string, value: any) => {
    if (!this.metaPropertyValueService.setPlainValue(value, metaPropertyPath, this.genericObject)) {
      return; // no change
    }

    this.notifyChangeHandlers(this.createObjectBookmarkEvent('changed', [metaPropertyPath]));
  };

  reset = (preventUIUpdate?: boolean) => {
    this.genericObject = cloneDeep(this.originalObject);
    this.hasInternalChanges = false;
    this.clearAllFieldErrors();

    if (!preventUIUpdate) {
      this.notifyChangeHandlers(this.createObjectBookmarkEvent('reset'));
    }
  };

  validate = async (): Promise<IValidationResult> => {
    const validationResult = this.validator.validate(this.layout, this.genericObject);

    this.updateGeneralErrors(validationResult.generalErrors);
    this.updateFieldErrors(validationResult.fieldErrors);

    validationResult.hasErrors = !(
      (validationResult.fieldErrors == null || validationResult.fieldErrors.length === 0) &&
      (validationResult.generalErrors == null || validationResult.generalErrors.length === 0)
    );

    return Promise.resolve(validationResult);
  };

  save = async (preventUIUpdate?: boolean): Promise<void> => {
    try {
      const genericObject = await this.persistenceService.save(this.genericObject, this.originalObject);
      await this.onSaved(genericObject, preventUIUpdate);
    } catch (e) {
      this.updateFieldErrors(isServerValidationError(e) ? e.validationInfo.errors : [], true);
      throw e;
    }
  };

  refresh = () => {
    this.reloadObject()
      .then((object: IGenericObject) => {
        if (!isNewObjectPrimaryKey(object.primaryKey)) {
          this.originalObject = object;
          this.genericObject = cloneDeep(this.originalObject);
          this.hasInternalChanges = false;
          this.clearAllFieldErrors();
        }
      })
      .catch((e) => {
        const message = 'Error while refreshing ObjectBookmark';
        this.logger.logError(`${message} ${JSON.stringify(e)}`);
      })
      .finally(() => this.notifyChangeHandlers(this.createObjectBookmarkEvent('reload')));
  };

  onSaved = async (updatedObject?: IGenericObject, preventUIUpdate?: boolean) => {
    this.originalObject = cloneDeep(updatedObject ? updatedObject : this.genericObject);
    this.genericObject = cloneDeep(this.originalObject);
    this.hasInternalChanges = false;
    this.clearAllFieldErrors(true);

    if (!preventUIUpdate) {
      await Promise.all(this.notifyChangeHandlers(this.createObjectBookmarkEvent('saved')));
    }

    // Render components AFTER onSaved events are processed
    this.rerenderKey = this.genericObject.primaryKey;
  };

  hasChanges = (): boolean => {
    const hasFieldChange = Object.keys(this.originalObject).find((x) => {
      const element = this.originalObject[x];
      if ((typeof element !== 'object' || element === null) && (typeof this.genericObject[x] !== 'object' || this.genericObject[x] === null)) {
        try {
          const metaElement = this.metaClass.properties.find((prop) => prop.path === x);
          if (metaElement?.transient) {
            return false;
          }

          if (metaElement != null) {
            switch (metaElement.dataType) {
              case DataType.Date:
              case DataType.Time:
              case DataType.DateTime:
                return new Date(element).toISOString() !== new Date(this.genericObject[x]).toISOString();
              case DataType.Text:
                return ObjectBookmark.normalizeLineEndings(element) !== ObjectBookmark.normalizeLineEndings(this.genericObject[x]);
              default:
                return element !== this.genericObject[x];
            }
          }

          // Fallback handling in case we can't find the meta property.
          if (typeof element === 'string' && !isNaN(new Date(element).getTime()) && !isNaN(new Date(this.genericObject[x]).getTime())) {
            return new Date(element).toISOString() !== new Date(this.genericObject[x]).toISOString();
          }
        } catch (e) {
          // it is not a valid date continue
        }

        return element !== this.genericObject[x];
      }

      return false;
    });

    const isAnyControlModified =
      this.controlModifiedProvider.getAllExecutingFunctions().find((executingFunction) => executingFunction() === true) != undefined;

    return this.hasInternalChanges || hasFieldChange != null || isAnyControlModified;
  };

  subscribe = (callback: ((event: IObjectBookmarkEvent) => Promise<void>) | ((event: IObjectBookmarkEvent) => void)): Symbol => {
    const symbol = Symbol();
    this.changeHandlers.set(symbol, callback);

    return symbol;
  };

  unsubscribe = (symbol: Symbol): void => {
    this.changeHandlers.delete(symbol);
  };

  notifyChangeHandlers = (event: IObjectBookmarkEvent): (Promise<void> | void)[] => {
    const promises: (Promise<void> | void)[] = [];
    this.changeHandlers.forEach((value) => promises.push(value(event)));
    return promises;
  };

  setHasChanges(hasInternalChanges: boolean) {
    if (this.hasInternalChanges === hasInternalChanges) {
      return;
    }

    this.hasInternalChanges = hasInternalChanges;

    this.notifyChangeHandlers(this.createObjectBookmarkEvent('changedInternally'));
  }

  setIsExecuting(isExecuting: boolean) {
    if (this.isExecutingInternal === isExecuting) {
      return;
    }

    this.isExecutingInternal = isExecuting;

    this.notifyChangeHandlers(this.createObjectBookmarkEvent('executionStateChanged'));
  }

  isExecuting(): boolean {
    return this.isExecutingInternal;
  }

  createObjectBookmarkEvent(eventType: ObjectBookmarkEventType, changedProperties?: string[]): IObjectBookmarkEvent {
    return {
      type: eventType,
      genericObject: this.genericObject,
      changedProperties: changedProperties ? changedProperties : []
    } as IObjectBookmarkEvent;
  }

  rootNodeIsTabContainer(): boolean {
    const layoutWithoutActions = this.getLayoutElementsWithoutActions().filter((element) =>
      this.visibleCalculator.calculate(element, this.genericObject)
    );
    return layoutWithoutActions.length > 0 ? layoutWithoutActions[0].controlName === CONTROL_IDENTIFIER.TABCONTAINER : false;
  }

  getLayoutElementsWithoutActions(): IMetaElement[] {
    return this.layout.elements.filter((element) => !isIMetaAction(element) && element.controlName !== CONTROL_IDENTIFIER.OBJECTSUMMARY);
  }

  registerChild(bookmark: IListBookmark): symbol {
    const sym = Symbol();
    this.childBookmarks.set(sym, bookmark);
    return sym;
  }

  unregisterChild(sym: symbol): void {
    this.childBookmarks.delete(sym);
  }

  hasChildChanges(): boolean {
    return Array.from(this.childBookmarks.values()).some((l) => l.hasInlineEditChanges());
  }

  readonly generalErrors: string[] = [];
  readonly fieldErrors: Map<string, IFieldError[]> = new Map<string, IFieldError[]>();
  readonly fieldErrorsFromServer: Map<string, IFieldError[]> = new Map<string, IFieldError[]>();

  originalObject: IGenericObject;
  rerenderKey: string | undefined;
  parentBookmark: IObjectBookmark | undefined;
  pagingPositionAbsolute: number | undefined;

  private static normalizeLineEndings(text: string): string {
    return text.replace(/\r\n/g, '\n');
  }

  private updateGeneralErrors(generalErrors: string[] | undefined) {
    this.generalErrors.splice(0, this.generalErrors.length);
    if (generalErrors) {
      generalErrors.forEach((x) => this.generalErrors.push(x));
    }
  }

  private updateFieldErrors(fieldErrors: IFieldError[] | undefined, errorsFromServer?: boolean) {
    const errorMap = errorsFromServer ? this.fieldErrorsFromServer : this.fieldErrors;
    const previousErrors = errorsFromServer ? Array.from(this.fieldErrorsFromServer.keys()) : [];

    errorMap.clear();

    if (fieldErrors && fieldErrors.length > 0) {
      fieldErrors.forEach((x) => {
        if (errorMap.has(x.fieldName)) {
          errorMap.get(x.fieldName)!.push(x);
        } else {
          errorMap.set(x.fieldName, [x]);
        }
      });
    }

    const changedProperties = new Set(previousErrors.concat(Array.from(errorMap.keys())));
    this.notifyChangeHandlers({
      type: 'changed',
      genericObject: this.genericObject,
      changedProperties: Array.from(changedProperties)
    } as IObjectBookmarkEvent);
  }

  private clearAllFieldErrors(notifyHandlers?: boolean) {
    const previousServerErrorFields = Array.from(this.fieldErrorsFromServer.keys());

    this.fieldErrors.clear();
    this.fieldErrorsFromServer.clear();

    if (notifyHandlers && previousServerErrorFields.length > 0) {
      this.notifyChangeHandlers({
        type: 'changed',
        genericObject: this.genericObject,
        changedProperties: previousServerErrorFields
      } as IObjectBookmarkEvent);
    }
  }

  private isExecutingInternal = false;
  private readonly childBookmarks = new Map<symbol, IListBookmark>();
  private hasInternalChanges: boolean;
  private readonly reloadObject: () => Promise<IGenericObject>;

  private readonly changeHandlers = new Map<
    Symbol,
    ((genericObject: IObjectBookmarkEvent) => Promise<void>) | ((genericObject: IObjectBookmarkEvent) => void)
  >();
}
