import { IBookmark } from './IBookmark';
import { IBookmarkActionsAspect } from '../aspects';

export type TBookmarkActionsVisible = { refresh: boolean; saveAndNew: boolean; discard: boolean; save: boolean; create: boolean; delete: boolean };

export const IBookmarkActionsVisibilityServiceSymbol = Symbol.for('IBookmarkActionsVisibilityService');

export interface IBookmarkActionsVisibilityService {
  getVisibility(bookmark: IBookmark, actionOptions?: IBookmarkActionsAspect | null): TBookmarkActionsVisible;
}
