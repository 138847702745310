import { inject, injectable } from 'inversify';
import { ICheckboxConfigurationService } from './ICheckboxConfigurationService';
import { ICheckboxConfiguration, ICheckboxConfigurationSymbol } from './ICheckboxConfiguration';
import { getAspectOrDefault, IConfigurationService, IConfigurationServiceSymbol, IMetaElement } from '@quino/core';
import { CheckboxConfigurationAspectIdentifier, ICheckboxConfigurationAspect } from '../../aspects';

@injectable()
export class DefaultCheckboxConfigurationService implements ICheckboxConfigurationService {
  constructor(
    @inject(ICheckboxConfigurationSymbol) private readonly configuration: ICheckboxConfiguration,
    @inject(IConfigurationServiceSymbol) private readonly configService: IConfigurationService
  ) {}

  async getCheckboxConfiguration(element: IMetaElement): Promise<ICheckboxConfiguration> {
    const result = this.configuration;

    // Apply global remote config
    this.configService.apply(result, 'CheckboxConfiguration');

    // Apply custom options from the element
    let customOverlay = null;
    if (element) {
      const aspect = getAspectOrDefault<ICheckboxConfigurationAspect>(element, CheckboxConfigurationAspectIdentifier);
      customOverlay = aspect != null ? aspect.configuration : {};
    }

    return { ...result, ...customOverlay };
  }
}
