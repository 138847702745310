import { usePromise } from '../../components/Util/PromiseReducer';
import { ICalendarConfiguration } from './ICalendarConfiguration';
import { ICalendarAspect } from '../aspect';
import { ICalendarConfigurationService, ICalendarConfigurationServiceSymbol } from './ICalendarConfigurationService';
import { useService } from '../../ioc';

export function useCalendarConfiguration(aspect: ICalendarAspect): ICalendarConfiguration | undefined {
  const calendarConfigurationService = useService<ICalendarConfigurationService>(ICalendarConfigurationServiceSymbol);

  const [calendarConfiguration] = usePromise<ICalendarConfiguration, any>(
    async () => calendarConfigurationService.getCalendarConfiguration(aspect),
    [aspect]
  );

  return calendarConfiguration;
}
