import { getAspectOrDefault, IMetaProperty } from '../../meta';
import { IFieldValidationResult } from '../IFieldValidationResult';
import { inject, injectable } from 'inversify';
import { QuinoCoreServiceSymbols } from '../../ioc';
import { IFieldValidator } from '../IFieldValidator';
import { IValidationContext } from '../IValidationContext';
import { IExpressionEvaluator } from '../../expressions';
import { ExpressionBasedValidationAspectIdentifier, IExpressionBasedValidationAspect } from '../../meta/aspects/IExpressionBasedValidationAspect';
import { FieldValidationErrorCodes } from '../FieldValidationErrorCodes';

@injectable()
export class CustomExpressionValidator implements IFieldValidator {
  constructor(@inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator) {}

  validate(field: IMetaProperty, value: any, context: IValidationContext): IFieldValidationResult {
    const validationAspect = getAspectOrDefault<IExpressionBasedValidationAspect>(field, ExpressionBasedValidationAspectIdentifier);
    if (!validationAspect) {
      return {};
    }

    const isValid = this.expressionEvaluator.evaluate<boolean>(validationAspect.expression, context ? context.data : {});

    return isValid
      ? {}
      : {
          fieldErrors: [
            {
              errorCode: FieldValidationErrorCodes.CUSTOM_EXPRESSION,
              errorMessage: validationAspect.messageTemplate,
              fieldName: field.name
            }
          ]
        };
  }
}
