import React, { useCallback, useEffect, useState } from 'react';
import {
  ClassActionAspectIdentifier,
  getResponsiveClassName,
  IBookmark,
  IClassActionAspect,
  ILayoutActionsService,
  IQuinoActionFactory,
  IQuinoActionFactorySymbol,
  IQuinoBookmarkAction,
  IQuinoShortcutSettings,
  IQuinoShortcutSettingsSymbol,
  isICustomBookmark,
  isILayoutAwareBookmark,
  isIMetaClassAwareBookmark,
  IStandardBookmarkActionsService,
  LayoutActionsServiceSymbol,
  quinoActionPropValue,
  QuinoContextMenuButton,
  StandardBookmarkActionsServiceSymbol,
  useOnBookmarkAnyEvent,
  useRerender,
  useService,
  useShortcutHandler,
  TResponsiveMode,
  useResponsiveMode
} from '@quino/ui';
import { Button } from 'devextreme-react/button';
import { getAspectOrDefault, IMetaAction, IMetaElement, isIMetaAction, ITranslationService, ITranslationServiceSymbol } from '@quino/core';

interface ICombinedBookmarkActions {
  mainActions: IQuinoBookmarkAction[];
  iconActions: IQuinoBookmarkAction[];
}

export function CUIBookmarkActions(props: { bookmark: IBookmark; customBookmarkActions?: IQuinoBookmarkAction[] }) {
  const standardActionsService = useService<IStandardBookmarkActionsService>(StandardBookmarkActionsServiceSymbol);
  const layoutActionsService = useService<ILayoutActionsService>(LayoutActionsServiceSymbol);
  const quinoActionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);

  const { bookmark, customBookmarkActions } = props;
  const rerender = useRerender();
  useOnBookmarkAnyEvent(bookmark, () => rerender());
  const responsiveMode = useResponsiveMode();

  const [iconActions, setIconActions] = useState<IQuinoBookmarkAction[]>([]);
  const [mainActions, setMainActions] = useState<IQuinoBookmarkAction[]>([]);

  const extractClassActions = useCallback(
    (bookmark: IBookmark): ICombinedBookmarkActions => {
      const actionNames: Set<string> = new Set<string>();
      const iconElements: IQuinoBookmarkAction[] = [];
      const mainElements: IQuinoBookmarkAction[] = [];

      const extractActions = (baseElements: IMetaElement[]) => {
        const actionProperties = baseElements.filter((p) => isIMetaAction(p));
        actionProperties.forEach((p) => {
          if (!actionNames.has(p.name)) {
            const classActionAspect = getAspectOrDefault<IClassActionAspect>(p, ClassActionAspectIdentifier);
            if (classActionAspect) {
              const action = quinoActionFactory.createAction(p as unknown as IMetaAction) as IQuinoBookmarkAction;
              action.location = classActionAspect.showAsMainAction
                ? classActionAspect.showAtStartOfGroup
                  ? 'main-before'
                  : 'main-after'
                : classActionAspect.showAtStartOfGroup
                ? 'icon-priority'
                : 'icon-normal';
              action.buttonType = classActionAspect.isPrimary ? 'default' : undefined;
              action.buttonStylingMode = classActionAspect.isPrimary ? 'contained' : undefined;
              classActionAspect.showAsMainAction ? mainElements.push(action) : iconElements.push(action);
              actionNames.add(p.name);
            }
          }
        });
      };

      if (isILayoutAwareBookmark(bookmark)) {
        extractActions(bookmark.layout.elements);
      }

      if (isIMetaClassAwareBookmark(bookmark)) {
        extractActions(bookmark.metaClass.properties);
      }

      return { mainActions: mainElements, iconActions: iconElements };
    },
    [quinoActionFactory]
  );

  const extractStandardBookmarkActions = useCallback(
    (bookmark: IBookmark): ICombinedBookmarkActions => {
      const mainElements: IQuinoBookmarkAction[] = [];
      const iconElements: IQuinoBookmarkAction[] = [];

      const standardActions = standardActionsService.getBookmarkActions(bookmark);
      standardActions.length > 0 &&
        standardActions.forEach((a) => {
          if (a.location === 'icon-normal' || a.location === 'icon-overflow') {
            iconElements.push(a);
          } else {
            mainElements.push(a);
          }
        });

      return { mainActions: mainElements, iconActions: iconElements };
    },
    [standardActionsService]
  );

  const extractCustomActions = useCallback((): ICombinedBookmarkActions => {
    const iconElements: IQuinoBookmarkAction[] = [];
    const mainElements: IQuinoBookmarkAction[] = [];

    customBookmarkActions &&
      customBookmarkActions.length > 0 &&
      customBookmarkActions.forEach((a) => {
        if (a.location === 'main-after' || a.location === 'main-before') {
          mainElements.push(a);
        } else {
          iconElements.push(a);
        }
      });

    return { mainActions: mainElements, iconActions: iconElements };
  }, [customBookmarkActions]);

  useEffect(() => {
    const classActions = extractClassActions(bookmark);
    const standardActions = extractStandardBookmarkActions(bookmark);
    const customActions = extractCustomActions();

    const mainNonStandardActions = [...classActions.mainActions, ...customActions.mainActions];
    const mainBeforeActions: IQuinoBookmarkAction[] = [];
    const mainAfterActions: IQuinoBookmarkAction[] = [];
    mainNonStandardActions.length > 0 &&
      mainNonStandardActions.forEach((a) => {
        if (a.location === 'main-after') {
          mainAfterActions.push(a);
        } else {
          mainBeforeActions.push(a);
        }
      });

    const sidebarActionsOnTouchDevices: IQuinoBookmarkAction[] =
      responsiveMode !== TResponsiveMode.Desktop
        ? layoutActionsService.getSidebarActions(bookmark).map((a) => {
            return { ...a, location: 'icon-overflow' };
          })
        : [];

    setMainActions([...mainBeforeActions, ...standardActions.mainActions, ...mainAfterActions]);
    setIconActions([...standardActions.iconActions, ...customActions.iconActions, ...classActions.iconActions, ...sidebarActionsOnTouchDevices]);
  }, [bookmark, extractClassActions, extractCustomActions, extractStandardBookmarkActions, layoutActionsService, responsiveMode]);

  RegisterShortcuts(bookmark);

  return (
    <div className={getResponsiveClassName('quino-ecui-sticky-header-actions-wrapper', responsiveMode)}>
      <CUIIconBookmarkActions bookmark={bookmark} actions={iconActions} isTouch={responsiveMode !== TResponsiveMode.Desktop} />
      <CUIMainBookmarkActions bookmark={bookmark} actions={mainActions} responsiveMode={responsiveMode} />
    </div>
  );
}

function RegisterShortcuts(bookmark: IBookmark) {
  const standardActionsService = useService<IStandardBookmarkActionsService>(StandardBookmarkActionsServiceSymbol);
  const shortcutSettings = useService<IQuinoShortcutSettings>(IQuinoShortcutSettingsSymbol);

  useShortcutHandler([shortcutSettings.saveDetail], () => standardActionsService.getObjectSaveMethod()(bookmark), true, {
    keyHandlerDisabled: !standardActionsService.objectSaveActive(bookmark)
  });
  useShortcutHandler([shortcutSettings.discardDetail], () => standardActionsService.getObjectDiscardMethod()(bookmark), true, {
    keyHandlerDisabled: !standardActionsService.objectDiscardActive(bookmark)
  });
  useShortcutHandler([shortcutSettings.deleteDetailPrimary], () => standardActionsService.getObjectDeleteMethod()(bookmark), true, {
    keyHandlerDisabled: !standardActionsService.objectDeleteActive(bookmark)
  });
  useShortcutHandler([shortcutSettings.forceDeleteDetailPrimary], () => standardActionsService.getObjectDirectDeleteMethod()(bookmark), true, {
    keyHandlerDisabled: !standardActionsService.objectDeleteActive(bookmark)
  });
  useShortcutHandler([shortcutSettings.createEntryPrimary], () => standardActionsService.getObjectCreateMethod()(bookmark), true, {
    keyHandlerDisabled: !standardActionsService.createActive(bookmark)
  });
}

function CUIIconBookmarkActions(props: { bookmark: IBookmark; actions: IQuinoBookmarkAction[]; isTouch: boolean }) {
  const translationService = useService<ITranslationService>(ITranslationServiceSymbol);
  const actionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);
  const [singleButtonActions, setSingleButtonActions] = useState<IQuinoBookmarkAction[]>([]);
  const [contextMenuActions, setContextMenuActions] = useState<IQuinoBookmarkAction[]>([]);

  useEffect(() => {
    const priorityActions: IQuinoBookmarkAction[] = [];
    const normalActions: IQuinoBookmarkAction[] = [];
    const overflowActions: IQuinoBookmarkAction[] = [];
    props.actions.length > 0 &&
      props.actions.forEach((a) => {
        if (a.location === 'icon-priority') {
          priorityActions.push(a);
        } else if (a.location === 'icon-overflow') {
          overflowActions.push(a);
        } else {
          normalActions.push(a);
        }
      });

    const maxVisible = props.isTouch ? 0 : 3;
    const allVisible = [...priorityActions, ...normalActions];
    setSingleButtonActions(allVisible.length > maxVisible ? allVisible.slice(0, maxVisible) : allVisible);
    setContextMenuActions(allVisible.length > maxVisible ? [...allVisible.slice(maxVisible), ...overflowActions] : overflowActions);
  }, [props.bookmark, props.actions, props.isTouch]);

  const contextMenuItems = contextMenuActions
    .map((a) => actionFactory.convertBookmarkActionToContextMenuItem(a, props.bookmark))
    .filter((value) => value.visible);

  return props.actions && props.actions.length > 0 ? (
    <div className={'quino-ecui-sticky-header-icon-actions'}>
      {singleButtonActions &&
        singleButtonActions.map((a, index) => {
          return a.children && a.children.length > 0 ? (
            <QuinoContextMenuButton
              key={'cui-icon-bookmark-action-' + index}
              buttonId={'cui-icon-bookmark-action-' + index}
              buttonIcon={quinoActionPropValue(a.icon, props.bookmark)}
              buttonStylingMode={quinoActionPropValue(a.buttonStylingMode, props.bookmark)}
              buttonType={quinoActionPropValue(a.buttonType, props.bookmark)}
              onButtonClick={() => a.onClick({ source: props.bookmark })}
              dataSource={a.children.map((a) => actionFactory.convertBookmarkActionToContextMenuItem(a, props.bookmark))}
            />
          ) : (
            <Button
              key={'cui-icon-bookmark-action-' + index}
              onClick={() => a.onClick({ source: props.bookmark })}
              icon={quinoActionPropValue(a.icon, props.bookmark)}
              hint={quinoActionPropValue(a.hint, props.bookmark)}
              visible={quinoActionPropValue(a.visible, props.bookmark)}
              disabled={quinoActionPropValue(a.disabled, props.bookmark)}
              type={quinoActionPropValue(a.buttonType, props.bookmark) ?? 'normal'}
              stylingMode={quinoActionPropValue(a.buttonStylingMode, props.bookmark) ?? 'text'}
            />
          );
        })}

      {contextMenuItems.length > 0 && (
        <QuinoContextMenuButton
          buttonId={'cui-icon-bookmark-actions-overflow'}
          buttonIcon={'material-icons-outlined more_vert'}
          buttonHintKey={translationService.translate('ActionArea.MoreActions')}
          dataSource={contextMenuItems}
        />
      )}
    </div>
  ) : (
    <></>
  );
}

function CUIMainBookmarkActions(props: { bookmark: IBookmark; actions: IQuinoBookmarkAction[]; responsiveMode: TResponsiveMode }) {
  const visibleActions = props.actions.filter((a) => quinoActionPropValue(a.visible, props.bookmark));
  const actionFactory = useService<IQuinoActionFactory>(IQuinoActionFactorySymbol);
  return (
    <>
      {visibleActions.length > 0 ? (
        <div className={getResponsiveClassName('quino-ecui-sticky-header-main-actions', props.responsiveMode)}>
          {props.actions.map((a, i) => {
            return a.children && a.children.length > 0 ? (
              <QuinoContextMenuButton
                key={'cui-icon-bookmark-action-' + i}
                buttonId={'cui-icon-bookmark-action-' + i}
                buttonIcon={quinoActionPropValue(a.icon, props.bookmark)}
                buttonText={props.responsiveMode === TResponsiveMode.Desktop ? quinoActionPropValue(a.caption, props.bookmark) : ''}
                buttonStylingMode={quinoActionPropValue(a.buttonStylingMode, props.bookmark) ?? 'outlined'}
                buttonType={quinoActionPropValue(a.buttonType, props.bookmark) ?? 'default'}
                onButtonClick={() => a.onClick({ source: props.bookmark })}
                dataSource={a.children.map((a) => actionFactory.convertBookmarkActionToContextMenuItem(a, props.bookmark))}
              />
            ) : (
              <Button
                key={'cui-main-bookmark-action-' + i}
                onClick={() => a.onClick({ source: props.bookmark })}
                icon={quinoActionPropValue(a.icon, props.bookmark)}
                text={props.responsiveMode === TResponsiveMode.Desktop ? quinoActionPropValue(a.caption, props.bookmark) : ''}
                hint={quinoActionPropValue(a.hint, props.bookmark)}
                visible={quinoActionPropValue(a.visible, props.bookmark)}
                disabled={quinoActionPropValue(a.disabled, props.bookmark)}
                type={quinoActionPropValue(a.buttonType, props.bookmark) ?? 'default'}
                stylingMode={quinoActionPropValue(a.buttonStylingMode, props.bookmark) ?? 'outlined'}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {isICustomBookmark(props.bookmark) && props.bookmark.renderActions()}
    </>
  );
}
