import { IExpressionEvaluator, INamespace, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';
import { INavigationService, INavigationServiceSymbol } from '../navigation';
import { isIObjectBookmark, isNewObjectBookmark } from '../bookmarks';

export const ThisNamespaceSymbol = Symbol.for('ThisNamespace');

@injectable()
export class ThisNamespace implements INamespace {
  constructor(
    @inject(INavigationServiceSymbol) private readonly navigationService: INavigationService,
    @inject(QuinoCoreServiceSymbols.IExpressionEvaluator) private readonly expressionEvaluator: IExpressionEvaluator
  ) {}
  name = 'this';
  Persisted = (): unknown => {
    const bookmark = this.navigationService.peek();
    if (!isIObjectBookmark(bookmark)) {
      return false;
    }
    return !isNewObjectBookmark(bookmark);
  };

  Deleted = (): unknown => {
    return false;
  };

  ValuesChanged = (): unknown => {
    const bookmark = this.navigationService.peek();
    if (!isIObjectBookmark(bookmark)) {
      return false;
    }
    return bookmark.hasChanges();
  };

  Edited = (): unknown => {
    const bookmark = this.navigationService.peek();
    if (!isIObjectBookmark(bookmark)) {
      return false;
    }
    return bookmark.hasChanges();
  };

  HasChildChanges = (): unknown => {
    const bookmark = this.navigationService.peek();
    if (!isIObjectBookmark(bookmark)) {
      return false;
    }
    return bookmark.hasChildChanges();
  };
}
