import { IUrlHelper, QuinoCoreServiceSymbols } from '@quino/core';
import { inject, injectable } from 'inversify';
import { IStateFullBookmarkSerializer } from './IStateFullBookmarkSerializer';
import { IBookmark, isStateFullBookmark } from '../bookmarks';

export const StateFullBookmarkSerializerSymbol = Symbol.for('StateFullBookmarkSerializer');

@injectable()
export class StateFullBookmarkSerializer implements IStateFullBookmarkSerializer {
  constructor(@inject(QuinoCoreServiceSymbols.IUrlHelper) private readonly urlHelper: IUrlHelper) {}

  deserializeState(path: string, bookmark: IBookmark): IBookmark {
    if (!isStateFullBookmark(bookmark)) {
      return bookmark;
    }

    let search: string[] = path.split('?').filter((x) => x !== '');
    if (search.length > 1) {
      search = search[1].split('&');
    } else {
      search = [];
    }
    search.forEach((value) => {
      const parameter = value.split('=');
      if (parameter.length > 1) {
        bookmark.setStateValue(decodeURIComponent(parameter[0]), decodeURIComponent(parameter[1]));
      }
    });

    return bookmark;
  }

  serializeState(bookmark: IBookmark): string {
    if (isStateFullBookmark(bookmark)) {
      return this.urlHelper.combineQueryParams(bookmark.getState());
    }
    return '';
  }
}
