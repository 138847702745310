export const ICheckboxConfigurationSymbol = Symbol.for('ICheckboxConfiguration');

export interface ICheckboxConfiguration {
  /**
   * Boolean which indicates if the checkbox can have a null or undefined state
   */
  allowNullState: boolean;
}

export const defaultCheckboxConfiguration: ICheckboxConfiguration = {
  allowNullState: false
};
