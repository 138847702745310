import { DataType, IFieldError, IGenericObject, IMetaElement, IValidationResult } from '@quino/core';
import { ITrackableBookmark } from './ITrackableBookmark';
import { IMetaBookmark, isIMetaBookmark } from './IMetaBookmark';
import { IBookmark } from './IBookmark';
import { IListBookmark } from './IListBookmark';

export type ObjectBookmarkEventType = 'changed' | 'saved' | 'reset' | 'reload' | 'changedInternally' | 'executionStateChanged';

export interface IObjectBookmarkEvent {
  type: ObjectBookmarkEventType;
  genericObject: IGenericObject;
  changedProperties: string[];
}

/**
 * The object bookmark contains information on how to display a specific object.
 */
export interface IObjectBookmark extends IMetaBookmark, ITrackableBookmark {
  genericObject: IGenericObject;
  originalObject: IGenericObject;

  rerenderKey: string | undefined;

  pagingPositionAbsolute: number | undefined;

  updateFieldValue: (metaPropertyPath: string, value: any) => void;
  validate: () => Promise<IValidationResult>;

  // TODO: Move the event stuff to it's own interface.
  refresh: () => void;
  onSaved: (updatedObject?: IGenericObject, preventUIUpdate?: boolean) => Promise<void>;
  subscribe: (callback: ((event: IObjectBookmarkEvent) => Promise<void>) | ((event: IObjectBookmarkEvent) => void)) => Symbol;
  unsubscribe: (symbol: Symbol) => void;
  notifyChangeHandlers: (event: IObjectBookmarkEvent) => (Promise<void> | void)[];
  createObjectBookmarkEvent(eventType: ObjectBookmarkEventType, changedProperties?: string[]): IObjectBookmarkEvent;

  generalErrors: string[];
  fieldErrors: Map<string, IFieldError[]>;
  fieldErrorsFromServer: Map<string, IFieldError[]>;

  rootNodeIsTabContainer: () => boolean;
  getLayoutElementsWithoutActions: () => IMetaElement[];

  registerChild(bookmark: IListBookmark): symbol;
  unregisterChild(sym: symbol): void;
  hasChildChanges(): boolean;

  setIsExecuting(isExecuting: boolean): void;
  isExecuting(): boolean;
}

export const isIObjectBookmark = (object: any): object is IObjectBookmark => {
  return isIMetaBookmark(object) && (object as IObjectBookmark).genericObject !== undefined;
};

export const isGuidPrimaryKey = (bookmark: IBookmark | undefined) => {
  if (isIObjectBookmark(bookmark)) {
    return bookmark.metaClass.properties.find((x) => x.name === bookmark.metaClass.primaryKey[0])!.dataType === DataType.Guid;
  }
  return false;
};

export const isNewObjectPrimaryKey = (primaryKey: any) => {
  return (
    primaryKey === null || primaryKey === 'null' || primaryKey === '0' || primaryKey === 0 || primaryKey === '00000000-0000-0000-0000-000000000000'
  );
};

export const isNewObjectBookmark = (bookmark: IBookmark | undefined) => {
  if (isIObjectBookmark(bookmark)) {
    const newBookmarkPrimaryKey = isGuidPrimaryKey(bookmark) ? '00000000-0000-0000-0000-000000000000' : '0';

    return bookmark.originalObject.primaryKey === 'null' || bookmark.originalObject.primaryKey === newBookmarkPrimaryKey;
  }
  return false;
};
