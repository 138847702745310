import { inject, injectable } from 'inversify';
import { INamespace } from './INamespace';
import { ILanguageService, ITranslationService, ITranslationServiceSymbol } from '../../lang';
import { QuinoCoreServiceSymbols } from '../../ioc';

export const TranslationNamespaceSymbol = Symbol.for('TranslationNamespace');

@injectable()
export class TranslationNamespace implements INamespace {
  constructor(
    @inject(ITranslationServiceSymbol) private readonly translationService: ITranslationService,
    @inject(QuinoCoreServiceSymbols.ILanguageService) private readonly languageService: ILanguageService
  ) {}

  Translate = (key: string, context: any): string => {
    return this.translationService.translate(key, context);
  };

  Current = (): string | null => {
    return this.languageService.getDeterminedLanguage();
  };

  name = 'Language';
}
