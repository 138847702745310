import { FrameworkStartupGroup, IApplication } from '../application';
import { QuinoCoreServiceSymbols } from '../ioc';
import { IExpressionEvaluatorProvider } from './IExpressionEvaluatorProvider';
import { ExpressionEvaluatorProvider } from './ExpressionEvaluatorProvider';
import { IExpressionEvaluator } from './IExpressionEvaluator';
import { AggregatingExpressionEvaluator } from './AggregatingExpressionEvaluator';
import { BinaryExpressionEvaluator, BinaryExpressionEvaluatorSymbol } from './BinaryExpressionEvaluator';
import { UnaryExpressionEvaluator, UnaryExpressionEvaluatorSymbol } from './UnaryExpressionEvaluator';
import { ConstantExpressionEvaluator, ConstantExpressionEvaluatorSymbol } from './ConstantExpressionEvaluator';
import { CreateGuidExpressionEvaluator, CreateGuidExpressionEvaluatorSymbol } from './CreateGuidExpressionEvaluator';
import { InnerExpressionEvaluator, InnerExpressionEvaluatorSymbol } from './InnerExpressionEvaluator';
import { MetaPropertyExpressionEvaluator, MetaPropertyExpressionEvaluatorSymbol } from './MetaPropertyExpressionEvaluator';
import { MetaRelationExpressionEvaluator, MetaRelationExpressionEvaluatorSymbol } from './MetaRelationExpressionEvaluator';
import { IfExpressionEvaluator, IfExpressionEvaluatorSymbol } from './IfExpressionEvaluator';
import { RequiredCalculator } from './calculators/RequiredCalculator';
import { EnabledCalculator } from './calculators/EnabledCalculator';
import { ReadOnlyCalculator } from './calculators/ReadOnlyCalculator';
import { IEnabledCalculator } from './calculators/IEnabledCalculator';
import { IReadOnlyCalculator } from './calculators/IReadOnlyCalculator';
import { IRequiredCalculator } from './calculators/IRequiredCalculator';
import { IVisibleCalculator } from './calculators/IVisibleCalculator';
import { VisibleCalculator } from './calculators/VisibleCalculator';
import { TextGroupExpressionEvaluator, TextGroupExpressionEvaluatorSymbol } from './TextGroupExpressionEvaluator';
import { GenericIdentifierExpressionEvaluator, GenericIdentifierExpressionEvaluatorSymbol } from './GenericIdentifierExpressionEvaluator';
import { IExpressionVisitor, IExpressionVisitorSymbol } from './IExpressionVisitor';
import { ExpressionVisitor } from './ExpressionVisitor';
import { ChainExpressionEvaluator, ChainExpressionEvaluatorSymbol } from './ChainExpressionEvaluator';
import { ContextDecoratorProvider, IContextDecoratorProvider, UserInfoContextDecorator } from '../context';
import { IAuthenticationService } from '../authentication';
import { NamespaceExpressionEvaluator, NamespaceExpressionEvaluatorSymbol } from './NamespaceExpressionEvaluator';
import { FunctionCallExpressionEvaluator, FunctionCallExpressionEvaluatorSymbol } from './FunctionCallExpressionEvaluator';
import { INamespaceProvider, NamespaceProvider, NamespaceProviderSymbol } from './namespaces';
import { DynamicStringCalculator } from './calculators/DynamicStringCalculator';
import { IDynamicStringCalculator, IDynamicStringCalculatorSymbol } from './calculators/IDynamicStringCalculator';
import { NowExpressionEvaluator, NowExpressionEvaluatorSymbol } from './NowExpressionEvaluator';
import { TranslationNamespace, TranslationNamespaceSymbol } from './namespaces/TranslationNamespace';
import { DateMathNamespace, DateMathNamespaceSymbol } from './namespaces/DateMathNamespace';
import { GlobalNamespace, GlobalNamespaceSymbol } from './namespaces/GlobalNamespace';

export const ExpressionRegistrationStartupActionIdentifier = Symbol.for('RegisterExpressionEvaluators');
export const ContextDecoratorStartupActionIdentifier = Symbol.for('quino_context_decorator_startup_action');

export class ExpressionModule {
  static use = (application: IApplication): IApplication => {
    application.registerSingle<IRequiredCalculator>(QuinoCoreServiceSymbols.IRequiredCalculator, RequiredCalculator);
    application.registerSingle<IEnabledCalculator>(QuinoCoreServiceSymbols.IEnabledCalculator, EnabledCalculator);
    application.registerSingle<IReadOnlyCalculator>(QuinoCoreServiceSymbols.IReadOnlyCalculator, ReadOnlyCalculator);
    application.registerSingle<IVisibleCalculator>(QuinoCoreServiceSymbols.IVisibleCalculator, VisibleCalculator);
    application.registerSingle<IDynamicStringCalculator>(IDynamicStringCalculatorSymbol, DynamicStringCalculator);

    application.registerSingle<IExpressionEvaluatorProvider>(QuinoCoreServiceSymbols.IExpressionEvaluatorProvider, ExpressionEvaluatorProvider);
    application.registerSingle<IExpressionEvaluator>(QuinoCoreServiceSymbols.IExpressionEvaluator, AggregatingExpressionEvaluator);
    application.registerSingle<INamespaceProvider>(NamespaceProviderSymbol, NamespaceProvider);

    application.registerSingle<BinaryExpressionEvaluator>(BinaryExpressionEvaluatorSymbol, BinaryExpressionEvaluator);
    application.registerSingle<TextGroupExpressionEvaluator>(TextGroupExpressionEvaluatorSymbol, TextGroupExpressionEvaluator);
    application.registerSingle<IfExpressionEvaluator>(IfExpressionEvaluatorSymbol, IfExpressionEvaluator);
    application.registerSingle<NowExpressionEvaluator>(NowExpressionEvaluatorSymbol, NowExpressionEvaluator);
    application.registerSingle<UnaryExpressionEvaluator>(UnaryExpressionEvaluatorSymbol, UnaryExpressionEvaluator);
    application.registerSingle<ConstantExpressionEvaluator>(ConstantExpressionEvaluatorSymbol, ConstantExpressionEvaluator);
    application.registerSingle<CreateGuidExpressionEvaluator>(CreateGuidExpressionEvaluatorSymbol, CreateGuidExpressionEvaluator);
    application.registerSingle<InnerExpressionEvaluator>(InnerExpressionEvaluatorSymbol, InnerExpressionEvaluator);
    application.registerSingle<MetaPropertyExpressionEvaluator>(MetaPropertyExpressionEvaluatorSymbol, MetaPropertyExpressionEvaluator);
    application.registerSingle<MetaRelationExpressionEvaluator>(MetaRelationExpressionEvaluatorSymbol, MetaRelationExpressionEvaluator);
    application.registerSingle<FunctionCallExpressionEvaluator>(FunctionCallExpressionEvaluatorSymbol, FunctionCallExpressionEvaluator);
    application.registerSingle<GenericIdentifierExpressionEvaluator>(
      GenericIdentifierExpressionEvaluatorSymbol,
      GenericIdentifierExpressionEvaluator
    );
    application.registerSingle<ChainExpressionEvaluator>(ChainExpressionEvaluatorSymbol, ChainExpressionEvaluator);
    application.registerSingle<NamespaceExpressionEvaluator>(NamespaceExpressionEvaluatorSymbol, NamespaceExpressionEvaluator);
    application.registerSingle<IExpressionVisitor>(IExpressionVisitorSymbol, ExpressionVisitor);
    application.registerSingle<IContextDecoratorProvider>(QuinoCoreServiceSymbols.IContextDecoratorProvider, ContextDecoratorProvider);

    application
      .registerStartupAction(ContextDecoratorStartupActionIdentifier, (app) => {
        const decorator = app.get<IContextDecoratorProvider>(QuinoCoreServiceSymbols.IContextDecoratorProvider);
        const authenticationService = app.get<IAuthenticationService>(QuinoCoreServiceSymbols.IAuthenticationService);
        decorator.register(new UserInfoContextDecorator(authenticationService), Symbol.for('UserInfoContextDecorator'));
      })
      .moveTo(FrameworkStartupGroup);

    application.registerSingle<GlobalNamespace>(GlobalNamespaceSymbol, GlobalNamespace);
    application.registerSingle<TranslationNamespace>(TranslationNamespaceSymbol, TranslationNamespace);
    application.registerSingle<DateMathNamespace>(DateMathNamespaceSymbol, DateMathNamespace);

    application
      .registerStartupAction(Symbol.for('ExpressionNamespaceStartupAction'), (app) => {
        const provider = app.get<INamespaceProvider>(NamespaceProviderSymbol);
        provider.register(app.get<GlobalNamespace>(GlobalNamespaceSymbol), GlobalNamespaceSymbol);
        provider.register(app.get<TranslationNamespace>(TranslationNamespaceSymbol), TranslationNamespaceSymbol);
        provider.register(app.get<DateMathNamespace>(DateMathNamespaceSymbol), DateMathNamespaceSymbol);
      })
      .moveTo(FrameworkStartupGroup);

    application
      .registerStartupAction(ExpressionRegistrationStartupActionIdentifier, (app) => {
        const provider = app.get<IExpressionEvaluatorProvider>(QuinoCoreServiceSymbols.IExpressionEvaluatorProvider);
        provider.register(app.get<BinaryExpressionEvaluator>(BinaryExpressionEvaluatorSymbol), BinaryExpressionEvaluatorSymbol);
        provider.register(app.get<ConstantExpressionEvaluator>(ConstantExpressionEvaluatorSymbol), ConstantExpressionEvaluatorSymbol);
        provider.register(app.get<UnaryExpressionEvaluator>(UnaryExpressionEvaluatorSymbol), UnaryExpressionEvaluatorSymbol);
        provider.register(app.get<IfExpressionEvaluator>(IfExpressionEvaluatorSymbol), IfExpressionEvaluatorSymbol);
        provider.register(app.get<NowExpressionEvaluator>(NowExpressionEvaluatorSymbol), NowExpressionEvaluatorSymbol);
        provider.register(app.get<CreateGuidExpressionEvaluator>(CreateGuidExpressionEvaluatorSymbol), CreateGuidExpressionEvaluatorSymbol);
        provider.register(app.get<MetaPropertyExpressionEvaluator>(MetaPropertyExpressionEvaluatorSymbol), MetaPropertyExpressionEvaluatorSymbol);
        provider.register(app.get<MetaPropertyExpressionEvaluator>(MetaRelationExpressionEvaluatorSymbol), MetaRelationExpressionEvaluatorSymbol);
        provider.register(app.get<InnerExpressionEvaluator>(InnerExpressionEvaluatorSymbol), InnerExpressionEvaluatorSymbol);
        provider.register(app.get<TextGroupExpressionEvaluator>(TextGroupExpressionEvaluatorSymbol), TextGroupExpressionEvaluatorSymbol);
        provider.register(app.get<FunctionCallExpressionEvaluator>(FunctionCallExpressionEvaluatorSymbol), FunctionCallExpressionEvaluatorSymbol);
        provider.register(app.get<NamespaceExpressionEvaluator>(NamespaceExpressionEvaluatorSymbol), NamespaceExpressionEvaluatorSymbol);
        provider.register(
          app.get<GenericIdentifierExpressionEvaluator>(GenericIdentifierExpressionEvaluatorSymbol),
          GenericIdentifierExpressionEvaluatorSymbol
        );
        provider.register(app.get<ChainExpressionEvaluator>(ChainExpressionEvaluatorSymbol), ChainExpressionEvaluatorSymbol);
      })
      .moveTo(FrameworkStartupGroup);

    return application;
  };
}
